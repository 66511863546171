import moment from "moment"

export const parseDate = (dateString: string) => {
	return moment(dateString).toDate()
}

export const formatDate = (date: Date, format: string) => {
	const formattedDate = moment(date).format(format)
	return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}

export const formatDateFull = (date: Date) => {
	return formatDate(date, "dddd, D [de] MMMM, YYYY")
}

export const formatDateMedium = (date: Date) => {
	return formatDate(date, "MMMM D, YYYY")
}

export const formatDateShort = (date: Date) => {
	return formatDate(date, "dddd D")
}

export const formatYYYYMMDD = (date: Date) => {
	return formatDate(date, "YYYY-MM-DD")
}

export const formatTime = (date: Date) => {
	return formatDate(date, "h:mm A")
}

export const formatTime24h = (date: Date) => {
	return formatDate(date, "HHmm")
}

export const formatYear = (date: Date) => {
	return formatDate(date, "YYYY")
}

export const isWorkingDay = (date: Date, workingDaysMask: ?string, absencesArray: ?Array<Date>) => {
	if (absencesArray) {
		var day = absencesArray.find((d) => {
			return formatDateShort(d) === formatDateShort(date)
		})

		if (day) {
			return false
		}
	}

	if (workingDaysMask) {
		let dayOfWeek = moment(date).format("d")
		return workingDaysMask.charAt(dayOfWeek) === "1"
	}

	return true
}

export const addDays = (date: Date, days: number) => {
	return moment(date).add(days, "d").toDate()
}

export const addMinutes = (date: Date, minutes: number) => {
	return moment(date).add(minutes, "m").toDate()
}

export const startOfDay = (date: Date) => {
	return moment(date).startOf("day").toDate()
}

export const endOfDay = (date: Date) => {
	return moment(date).endOf("day").toDate()
}

export const startOfMonth = (date: Date) => {
	return moment(date).startOf("month").toDate()
}

export const endOfMonth = (date: Date) => {
	return moment(date).endOf("month").toDate()
}

export const isToday = (date: Date) => {
	return moment(date).isSame(new Date(), "day")
}

export const isBeforeToday = (date: Date) => {
	return moment(date).isBefore(new Date(), "day")
}

export const isSame = (date: Date, otherDate: Date) => {
	return moment(date).isSame(moment(otherDate), "day")
}

export const setTime = (date: Date, workStartTime: string) => {
	const hour = workStartTime.substr(0, 2)
	const minutes = workStartTime.substr(2, 2)
	const newDate = moment(date).hour(hour)
	newDate.minutes(minutes)

	return newDate.toDate()
}

export const dateIsGreaterThanOrEqual = (date: Date, otherDate: Date) => {
	return moment(date).isSameOrAfter(moment(otherDate))
}

export const calculateAge = (date: Date) => {
	return moment().diff(date, "years")
}

export const calendarToDate = (day: any) => {
	return moment(day.dateString).toDate()
}

export const isSameMonth = (date: Date, month: Date) => {
	return moment(date).isSame(month, "month")
}