// @flow

import React from "react"

import Appointments from "../../utils/appointments"
import { cancelledStatuses, completedStatuses, inLineStatuses, pendingStatuses, finalizedStatuses } from "../../constants/values"
import { formatTime } from "../../utils/date"

import AppointmentButtons from "./appointmentButtons"

type Props = {
	appointment: any,
	onPressItem: ?Function,
	onSetCompleted: ?Function,
	onChangeStatus: ?Function,
	institution: any,
	professional: any,
	lastCompletionDate: ?Date,
	appointments: Array<any>
}

type LocalState = {
	appointment: any,
	user: any,
	institution: any,
	professional: any,
	lastCompletionDate: ?Date,
	appointments: Array<any>
}

export default class AppointmentItem extends React.PureComponent<Props, LocalState> {
	unsubscribeUser: Function

	static defaultProps = {
		appointment: undefined,
		onSetCompleted: undefined,
		onChangeStatus: undefined,
		institution: undefined,
		professional: undefined,
		lastCompletionDate: undefined,
		appointments: []
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			appointment: props.appointment,
			user: undefined,
			institution: props.institution,
			professional: props.professional,
			lastCompletionDate: props.lastCompletionDate,
			appointments: []
		}

		const { user } = props.appointment

		if (user) {
			this.unsubscribeUser = user.onSnapshot(this.onUserUpdate)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			appointment: nextProps.appointment,
			institution: nextProps.institution,
			professional: nextProps.professional,
			appointments: nextProps.appointments,
			lastCompletionDate: nextProps.lastCompletionDate
		})
	}

	componentWillUnmount() {
		if (this.unsubscribeUser)
			this.unsubscribeUser()
	}

	onUserUpdate = (doc: any) => {
		const { displayName } = doc.data()

		const user = {
			id: doc.id,
			ref: doc.ref,
			name: displayName
		}
		this.setState({
			user
		})
		console.warn("APPOINTMENT ITEM SUB 2")
	}

	onPress = () => {
		if (this.props.onPressItem) {
			this.props.onPressItem(this.props.appointment)
		}
	}

	statusStyle = (status: String) => {
		var statusStyle = "statusInLine"

		if (completedStatuses.includes(status)) {
			statusStyle = "statusCompleted"
		}
		else if (cancelledStatuses.includes(status)) {
			statusStyle = "statusCancelled"
		}
		else if (pendingStatuses.includes(status)) {
			statusStyle = "statusPending"
		}

		return statusStyle
	}

	render() {
		const { appointment, user, institution, professional, appointments, lastCompletionDate } = this.state
		const { id } = appointment
		const name = user ? user.name : ""

		const metadata = institution && professional && appointments.length > 0 ? Appointments.metadata(appointment, professional, lastCompletionDate, appointments) : undefined
		return (
			<div key={id} onClick={this.onPress}>
				<div className="topRow">
					<div>{name}</div>
					<div className={this.statusStyle(appointment.status)}>{appointment.status}</div>
				</div>
				{metadata && metadata.formatedTime && (pendingStatuses.includes(appointment.status) || inLineStatuses.includes(appointment.status)) ?
					< div>{metadata.pastStartTime ? metadata.pastFormatedTime : metadata.formatedTime}</div>
					:
					< div>{appointment.completionDate ? formatTime(appointment.completionDate) : " "}</div>
				}

				{!finalizedStatuses.includes(appointment.status) &&
					<AppointmentButtons appointment={appointment} onSetCompleted={this.props.onSetCompleted} onChangeStatus={this.props.onChangeStatus} />
				}
			</div >
		)
	}
}