// @flow
import ImageCompressor from "image-compressor.js"

import { storage } from "./firebase"

const uploadImageToFirebase = (dir: string, source: any, fileName: string) => {
	const imageRef = storage.ref(dir).child(fileName)

	return new Promise((resolve, reject) => {
		new ImageCompressor(source, {
			quality: .7,
			maxHeight: 1000,
			maxWidth: 1000,
			success(result) {
				imageRef.put(result)
					.then((snap) => {
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			},
			error(e) {
				reject(e)
			}
		})
	})
}

const getFileSourceFromFirebase = (dir: string, fileName: string, obj: any) => {
	return new Promise((resolve, reject) => {
		const ref = storage.ref(dir).child(fileName)
		ref.getDownloadURL().then((url) => {
			const source = {
				ref,
				src: url,
				thumbnail: url,
				obj
			}
			resolve(source)
		}).catch((error) => {
			reject(error)
		})
	})
}

export default {
	uploadImageToFirebase,
	getFileSourceFromFirebase
}