// @flow 

import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import * as ROUTES from "../../constants/routes"
import firebase from "../../utils/firebase"
import { parseDate } from "../../utils/date"

import Appointments from "./appointments"
import Appointment from "./appointment"
import Professional from "./professional"
import Calendar from "./calendar"

type Props = {
	history: any,
	match: any,
	user: any
}

type LocalState = {
	user: any,
	appointment: any,
	professional: any,
	institution: any,
	date: any,
	filterFinalized: boolean,
	appointmentFromClient: any,
	appointments: Array<any>
}

class AppointmentsPage extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			appointment: undefined,
			professional: undefined,
			institution: undefined,
			date: new Date(),
			filterFinalized: true,
			appointmentFromClient: undefined,
			appointments: []
		}
	}

	componentDidMount() {
		if (!this.props.user) {
			this.props.history.push(ROUTES.LOGIN)
			return
		}

		const { date, id, institution } = this.props.match.params

		if (date) {
			this.onSelectedDate(parseDate(date))
		}

		if (institution) {
			const ref = firebase.firestore().collection("institutions").doc(institution)
			this.onSelectedInstitution({ id: institution, ref })
		}

		if (id) {
			const ref = firebase.firestore().collection("appointments").doc(id)

			this.setState({ filterFinalized: false, appointmentFromClient: { ref, id } })
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
	}

	onSelectedAppointment = (appointment: any) => {
		this.setState({
			appointment
		})
	}

	onSelectedProfessional = (professional: any) => {
		this.setState({
			professional
		})
	}

	onSelectedInstitution = (institution: any) => {
		this.setState({
			institution,
			appointmentFromClient: undefined
		})
	}

	onSelectedDate = (date: Date) => {
		this.setState({
			date,
			appointmentFromClient: undefined
		})
	}

	onMonthAppointmentsChange = (appointments: Array<any>) => {
		this.setState({
			appointments
		})
	}

	onAppointmentFromAppointment = (appointment: any) => {
		this.onSelectedInstitution({ id: appointment.institution.id, ref: appointment.institution })
		this.onSelectedDate(appointment.date)
		this.setState({ filterFinalized: false, appointmentFromClient: { ref: appointment.ref, id: appointment.id } })
	}

	render() {
		return (
			<div className="appointments">
				<div className="calendar">
					<div className="content">
						<div className="box">
							<Professional user={this.state.user} institution={this.state.institution} onSelectedProfessional={this.onSelectedProfessional} onSelectedInstitution={this.onSelectedInstitution} />
							<Calendar user={this.state.user} date={this.state.date} professional={this.state.professional} institution={this.state.institution} onSelectedDate={this.onSelectedDate} onMonthAppointmentsChange={this.onMonthAppointmentsChange} />
							<Appointments user={this.state.user} date={this.state.date} professional={this.state.professional} institution={this.state.institution} monthAppointments={this.state.appointments} appointmentFromClient={this.state.appointmentFromClient} filterFinalized={this.state.filterFinalized} onSelectedAppointment={this.onSelectedAppointment} />
						</div>
					</div>
				</div>
				<Appointment user={this.state.user} date={this.state.date} professional={this.state.professional} institution={this.state.institution} appointment={this.state.appointment} onAppointmentFromAppointment={this.onAppointmentFromAppointment} />
			</div >
		)
	}
}

export default withRouter(AppointmentsPage)