// @flow 

import React, { Component } from "react"

import BasicHistory from "./basic"

type Props = {
	user: any,
	client: any
}

type LocalState = {
	user: any,
	client: any
}

class ClientsPage extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: undefined
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}

		if (this.props.client !== nextProps.client) {
			this.setState({
				client: nextProps.client
			})
		}
	}

	render() {
		const { client } = this.state

		if (!client) {
			return (
				<div />
			)
		}

		return (
			<div className="history">
				<div className="sectionTitle">Historial</div>
				<div className="history-disclaimer">La información de este historial se comparte con otros médicos autorizados por el paciente en el momento que realiza una primera consulta.</div>
				<BasicHistory user={this.state.user} client={this.state.client} />
			</div>
		)
	}
}

export default ClientsPage