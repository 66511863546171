// @flow

import React from "react"

type Props = {
	appointment: any
}

type LocalState = {
	appointment: any,
	notes: Array<any>,
	note: string
}

export default class Notes extends React.PureComponent<Props, LocalState> {
	unsubscribeNotes: Function

	constructor(props: Props) {
		super(props)

		this.state = {
			appointment: props.appointment,
			notes: [],
			note: ""
		}
	}

	componentDidMount() {
		const { appointment } = this.state
		this.subscribe(appointment)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.appointment !== nextProps.appointment) {
			this.setState({
				appointment: nextProps.appointment
			})

			this.subscribe(nextProps.appointment)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(appointment: any) {
		this.unsubscribeNotes = appointment.ref.collection("notes").orderBy("creationDate").onSnapshot(this.onNotesUpdate)
	}

	unsubscribe() {
		if (this.unsubscribeNotes)
			this.unsubscribeNotes()
	}

	onNotesUpdate = (snapshot: any) => {
		const notes = []
		snapshot.forEach((doc) => {
			const { note, creationDate } = doc.data()
			notes.push({
				ref: doc.ref,
				id: doc.id,
				creationDate,
				note
			})
		})

		this.setState({
			notes
		})

		console.warn("NOTES SUB 2")
	}

	addNote = (event: any) => {
		const { appointment, note } = this.state

		if (note.trim() !== "") {
			appointment.ref.collection("notes").add({
				note,
				creationDate: new Date()
			})
		}
		this.setState({
			note: ""
		})

		event.preventDefault()
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	removeNote = (note: any) => {
		note.ref.delete()
	}

	render() {
		const { notes } = this.state

		return (
			<div>
				<div className="appointmentLabel">Evaluación y Diagnóstico</div>
				<form className="notes-form" onSubmit={this.addNote}>
					<textarea className="note-text-area" type="text" multiple={true} name="note" onChange={this.handleChange} value={this.state.note} />
					<button className="create-button">Agregar</button>
				</form>
				<ul className="notesList">
					{notes.map((note) => {
						return (
							<li key={note.id} className="note-item">
								<div className="note-value" >{note.note}</div>
								<button className="note-remove" onClick={() => this.removeNote(note)}>
									{"Eliminar"}
								</button>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}