// @flow 

import React, { Component } from "react"

import firebase from "../../utils/firebase"
import * as ROUTES from "../../constants/routes"
import { startOfDay, endOfDay } from "../../utils/date"
import { inLineStatuses, Status } from "../../constants/values"

type Props = {
  history: any,
  user: any
}

type LocalState = {
  user: any,
  date: Date,
  appointments: ?Array<any>
}

class Dashboard extends Component<Props, LocalState> {
  unsubscribeAppointments: Function

  constructor(props: Props) {
    super(props)

    this.state = {
      user: props.user,
      date: new Date(),
      appointments: undefined
    }
  }

  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push(ROUTES.LOGIN)
      return
    }

    const { user } = this.state

    this.unsubscribeAppointments = firebase.firestore().collection("appointments").where("professional", "==", user.ref)
      .where("date", ">=", startOfDay(this.state.date))
      .where("date", "<", endOfDay(this.state.date))
      .orderBy("date")
      .orderBy("creationDate")
      .onSnapshot(this.onAppointmentsUpdate)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        user: nextProps.user
      })
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeAppointments)
      this.unsubscribeAppointments()
  }

  onAppointmentsUpdate = (snapshot: any) => {
    const appointments = []
    snapshot.forEach((doc) => {
      const { date, status, professional, user, institution, type, description, completionDate } = doc.data()
      var appointment = {
        id: doc.id,
        ref: doc.ref,
        date,
        professional,
        institution,
        type: type,
        description,
        status,
        user,
        completionDate
      }

      if (inLineStatuses.includes(appointment.status)) {
        appointments.push(appointment)
      }
    })

    this.setState({
      appointments
    })

    console.warn("DASHBOARD SUB 1")
  }

  openAppointments = () => {
    this.props.history.push(ROUTES.APPOINTMENTS)
  }

  render() {
    const { user, appointments } = this.state
    const name = user ? user.name : ""
    const numberOfAppoinments = appointments ? appointments.length : undefined

    return (
      <div>
        <h1>{`Hola, ${name}`}</h1>
        <div onClick={this.openAppointments}>
          {user && user.status === Status.Accepted && numberOfAppoinments && numberOfAppoinments > 0 ?
            < label > {`Tiene ${numberOfAppoinments} ${numberOfAppoinments > 1 ? "citas" : "cita"} en agenda para hoy. `}</label>
            :
            < label > {numberOfAppoinments === 0 ? "No hay citas pendientes para el dia de hoy." : ""}</label>
          }
        </div>

        {user && user.status !== Status.Accepted &&
          <div className="errorLabel">{`Su cuenta esta en estado ${user.status}. El uso de la app será limitado hasta ser activada por nuestro equipo.`}</div>
        }
      </div>
    )
  }
}

export default Dashboard