// @flow

import * as React from "react"
import { withRouter } from "react-router-dom"

import * as ROUTES from "../../constants/routes"
import firebase from "../../utils/firebase"
import { endOfDay, formatDateFull, formatYYYYMMDD, startOfDay } from "../../utils/date"
import { completedStatuses, finalizedStatuses, pastAppointmentsLimit } from "../../constants/values"
import { Status } from "../../constants/values"

import AddAppointment from "./addAppointment"
import ClientDetails from "./clientDetails"

type Props = {
	history: any,
	user: any,
	client: any
}

type LocalState = {
	user: any,
	client: any,
	nextAppointment: any,
	pastAppointments: Array<any>,
	pastNotes: any,
	pastPrescriptions: any
}

class Client extends React.Component<Props, LocalState> {
	unsubscribeNextAppointments: Function
	unsubscribePastAppointments: Function

	unsubscribePastNotes: Array<Function> = []
	unsubscribePastPrescriptions: Array<Function> = []

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: props.client,
			professional: undefined,
			nextAppointment: undefined,
			pastAppointments: [],
			pastNotes: {},
			pastPrescriptions: {}
		}
	}

	componentDidMount() {
		const { user, client } = this.state
		this.subscribe(user, client)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user ||
			this.props.client !== nextProps.client
		) {
			this.setState({
				user: nextProps.user,
				client: nextProps.client,
				pastAppointments: [],
				nextAppointment: undefined,
				pastNotes: {}
			})

			this.subscribe(nextProps.user, nextProps.client)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(user: any, client: any) {
		this.unsubscribe()

		if (user && client) {
			this.unsubscribeNextAppointments = firebase.firestore().collection("appointments").where("user", "==", client.ref)
				.where("professional", "==", user.ref)
				.where("date", ">=", startOfDay(new Date()))
				.orderBy("date")
				.orderBy("creationDate")
				.onSnapshot(this.onNextAppointments)

			this.unsubscribePastAppointments = firebase.firestore().collection("appointments").where("user", "==", client.ref)
				.where("professional", "==", user.ref)
				.where("date", "<", endOfDay(new Date()))
				.orderBy("date", "desc")
				.orderBy("creationDate", "desc")
				.onSnapshot(this.onPastAppointments)
		}
	}

	unsubscribe() {
		if (this.unsubscribeNextAppointments)
			this.unsubscribeNextAppointments()

		if (this.unsubscribePastAppointments)
			this.unsubscribePastAppointments()

		this.unsubscribePastNotes.forEach((unsub) => {
			unsub()
		})

		this.unsubscribePastPrescriptions.forEach((unsub) => {
			unsub()
		})
	}

	onNextAppointments = (snapshot: any) => {
		const appointments = []
		var nextAppointment = undefined

		snapshot.forEach((doc) => {
			appointments.push(doc)
		})

		const doc = appointments.find((doc) => {
			return !finalizedStatuses.includes(doc.data().status)
		})

		if (doc) {
			const { date, status, professional, user, institution, type, description } = doc.data()
			nextAppointment = {
				id: doc.id,
				ref: doc.ref,
				date,
				professional,
				institution,
				type: type,
				description,
				status,
				user
			}
		}

		this.setState({
			nextAppointment
		})

		console.warn("CLIENT SUB 3")
	}

	onPastAppointments = (snapshot: any) => {
		const pastAppointments = []
		var count = 0
		snapshot.forEach((doc) => {
			const { date, status, professional, user, institution, type, description, completionDate } = doc.data()
			var appointment = {
				id: doc.id,
				ref: doc.ref,
				date,
				professional,
				institution,
				type: type,
				description,
				status,
				user,
				completionDate
			}

			if (count < pastAppointmentsLimit && completedStatuses.includes(status)) {
				pastAppointments.push(appointment)
				count = count + 1
			}
		})

		this.subscribeNotes(pastAppointments)
		this.subscribePastPrescriptions(pastAppointments)

		this.setState({
			pastAppointments
		})

		console.warn("CLIENT SUB 2")
	}

	onNotesUpdate = (snapshot: any) => {
		const notes = []
		var appointment = ""
		snapshot.forEach((doc) => {
			const { id } = doc
			const { note, creationDate } = doc.data()

			appointment = doc.ref.parent.parent.id
			notes.push({
				ref: doc.ref,
				id,
				creationDate,
				note
			})
		})

		this.setState({
			pastNotes: {
				...this.state.pastNotes,
				[appointment]: notes
			}
		})

		console.warn("CLIENT SUB 1")
	}

	onPastPrescriptionsUpdate = (snapshot: any) => {
		const prescriptions = []
		var appointment = ""
		snapshot.forEach((doc) => {
			const { id } = doc
			const { prescription, creationDate } = doc.data()

			appointment = doc.ref.parent.parent.id
			prescriptions.push({
				ref: doc.ref,
				id,
				creationDate,
				prescription
			})
		})

		this.setState({
			pastPrescriptions: {
				...this.state.pastPrescriptions,
				[appointment]: prescriptions
			}
		})

		console.warn("PAST PRESCRIPTION SUB 1")
	}

	subscribeNotes = (pastAppointments: Array<any>) => {
		pastAppointments.forEach((appointment) => {
			this.unsubscribePastNotes.push(appointment.ref.collection("notes").orderBy("creationDate").onSnapshot(this.onNotesUpdate))
		})
	}

	subscribePastPrescriptions = (pastAppointments: Array<any>) => {
		pastAppointments.forEach((appointment) => {
			this.unsubscribePastPrescriptions.push(appointment.ref.collection("prescriptions").orderBy("creationDate").onSnapshot(this.onPastPrescriptionsUpdate))
		})
	}

	getPastNotes = (appointment: any) => {
		return this.state.pastNotes[appointment.id] || []
	}

	getPastPrescriptions = (appointment: any) => {
		return this.state.pastPrescriptions[appointment.id] || []
	}

	openAppointment(user: any, appointment: any) {
		this.props.history.push(ROUTES.APPOINTMENTS + "/" + formatYYYYMMDD(appointment.date) + "/" + appointment.institution.id + "/" + appointment.id)
	}

	render() {
		const { client, nextAppointment, pastAppointments, user } = this.state

		if (!client) {
			return (
				<div />
			)
		}

		return (
			<div className="client">
				{user && user.status === Status.Accepted &&
					<div>
						< AddAppointment user={user} client={client} />
						<hr />
					</div>
				}
				<div>
					<ClientDetails client={client} user={user} />
				</div>
				{nextAppointment &&
					<div>
						<hr />
						<div className="appointmentLabel">Fecha de próxima cita</div>
						<div className="appointment-item" onClick={() => this.openAppointment(user, nextAppointment)}>
							<label className="appointment-item-date">
								{formatDateFull(nextAppointment.date)}
							</label>
						</div>
					</div>
				}
				{pastAppointments.length > 0 &&
					<hr className="appointment-divider" />
				}
				{pastAppointments.length > 0 &&
					<div>
						<div className="appointmentLabel">Ultimas citas</div>

						{pastAppointments.map((appointment) => {
							return (
								<div className="appointment-item" key={appointment.id}
									onClick={() => this.openAppointment(user, appointment)}>
									<label className="appointment-item-date">
										{formatDateFull(appointment.date)}
									</label>
									<div className="appointment-item-description">
										{appointment.description}
									</div>

									{this.getPastNotes(appointment).length > 0 &&
										<hr />
									}
									<div>
										{this.getPastNotes(appointment).map((note) => {
											return (
												<div className="appointment-item-notes" key={note.id}>
													<div>{note.note}</div>
												</div>
											)
										})}
									</div>

									{this.getPastPrescriptions(appointment).length > 0 &&
										<hr />
									}
									<div>
										{this.getPastPrescriptions(appointment).map((prescription) => {
											return (
												<div className="appointment-item-notes" key={prescription.id}>
													<div>{prescription.prescription}</div>
												</div>
											)
										})}
									</div>
								</div>
							)
						})
						}
					</div>
				}
			</div>
		)
	}
}

export default withRouter(Client)