
import * as React from "react"

type Props = {
	prescriptions: Array<any>
}

type LocalState = {
	prescriptions: Array<any>
}

export default class PrintPrescriptions extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)
		this.state = {
			prescriptions: []
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.prescriptions !== nextProps.prescriptions) {
			this.setState({
				prescriptions: nextProps.prescriptions
			})
		}
	}

	render() {
		const { prescriptions } = this.state
		return (
			<div className="print">
				{prescriptions.length > 0 &&
					prescriptions.map((prescription, index) => {
						return (
							<div key={index} className="print-line-item">
								{prescription.prescription}
							</div>
						)
					})
				}
			</div>
		)
	}
}