// @flow

import * as React from "react"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import MomentLocaleUtils from "react-day-picker/moment"

import firebase from "../../utils/firebase"
import { addDays, formatDateFull, formatYYYYMMDD, isSame, startOfDay, startOfMonth, endOfMonth, isSameMonth, dateIsGreaterThanOrEqual } from "../../utils/date"
import { cancelledStatuses, completedStatuses, inLineStatuses } from "../../constants/values"

declare var IntervalID: any

type Props = {
	user: any,
	professional: any,
	institution: any,
	date: Date,
	onSelectedDate: Function,
	addMode: boolean,
	onAbsencesChanged: ?Function,
	onMonthAppointmentsChange: ?Function
}

type LocalState = {
	user: any,
	professional: any,
	institution: any,
	date: Date,
	currentDate: Date,
	absencesMode: boolean,
	absenceDatesTemp: Array<Date>,
	monthAppointments: any,
	month: Date,
	addMode: boolean
}

export default class Calendar extends React.Component<Props, LocalState> {
	userRef: any

	unsubscribeMonthAppointments: Function

	static defaultProps = {
		onSelectedAppointment: undefined,
		onAbsencesChanged: undefined,
		addMode: false,
		onMonthAppointmentsChange: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			professional: props.professional,
			institution: props.institution,
			date: startOfDay(props.date),
			currentDate: new Date(),
			absencesMode: false,
			absenceDatesTemp: [],
			monthAppointments: {},
			month: startOfDay(props.date),
			addMode: props.addMode
		}
	}

	componentDidMount() {
		const { month, professional, institution } = this.state
		this.subscribeAppointmentsForMonth(month, institution, professional)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}

		if (this.props.professional !== nextProps.professional) {
			this.setState({
				professional: nextProps.professional
			}, () => {
				const { month, professional, institution } = this.state
				this.subscribeAppointmentsForMonth(month, institution, professional)
			})
		}

		if (this.props.institution !== nextProps.institution) {
			this.setState({
				institution: nextProps.institution
			}, () => {
				const { month, professional, institution } = this.state
				this.subscribeAppointmentsForMonth(month, institution, professional)
			})
		}

		if (this.props.date !== nextProps.date) {
			const currentMonth = this.state.month
			this.setState({
				date: nextProps.date,
				month: nextProps.date
			}, () => {
				const { month, professional, institution } = this.state
				if (!isSameMonth(month, currentMonth)) {
					this.subscribeAppointmentsForMonth(month, institution, professional)
				}
			})
		}

		if (this.props.addMode !== nextProps.addMode) {
			this.setState({
				addMode: nextProps.addMode
			})
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	unsubscribe() {
		if (this.unsubscribeMonthAppointments)
			this.unsubscribeMonthAppointments()
	}

	onMonthAppointmentsUpdate = (snapshot: any) => {
		const monthAppointments = []
		const appointments = []
		snapshot.forEach((doc) => {
			const { date, status, user, institution, professional, type, description, completionDate } = doc.data()

			appointments.push({
				id: doc.id,
				ref: doc.ref,
				date,
				user,
				institution,
				professional,
				type,
				description,
				status,
				completionDate
			})

			if (inLineStatuses.includes(status)) {
				monthAppointments[formatYYYYMMDD(date)] = {
					...monthAppointments[formatYYYYMMDD(date)],
					inLine: true
				}
			}

			if (cancelledStatuses.includes(status)) {
				monthAppointments[formatYYYYMMDD(date)] = {
					...monthAppointments[formatYYYYMMDD(date)],
					cancelled: true
				}
			}

			if (completedStatuses.includes(status)) {
				monthAppointments[formatYYYYMMDD(date)] = {
					...monthAppointments[formatYYYYMMDD(date)],
					completed: true
				}
			}
		})

		this.setState({
			monthAppointments
		},
			() => {
				const { absences } = this.markedDates()

				if (this.props.onAbsencesChanged) {
					this.props.onAbsencesChanged(absences)
				}
			})

		if (this.props.onMonthAppointmentsChange) {
			this.props.onMonthAppointmentsChange(appointments)
		}
		console.warn("CALENDAR SUB 2")
	}

	subscribeAppointmentsForMonth = (date: Date, institution: any, professional: any) => {
		if (this.unsubscribeMonthAppointments)
			this.unsubscribeMonthAppointments()

		if (date && institution && professional) {
			this.unsubscribeMonthAppointments = firebase.firestore().collection("appointments").where("professional", "==", professional.ref)
				.where("institution", "==", institution.ref)
				.where("date", ">=", startOfMonth(date))
				.where("date", "<", endOfMonth(date))
				.orderBy("date")
				.orderBy("creationDate")
				.onSnapshot(this.onMonthAppointmentsUpdate)
		}
	}

	modifyDate = (days: number) => {
		this.handleDatePicked(addDays(this.state.date, days))
	}

	showToday = () => {
		const { absencesMode } = this.state

		if (!absencesMode) {
			const today = new Date()
			this.handleDatePicked(today)
			this.handleMonthChange(today)
		}
	}

	handleDatePicked = (date: Date) => {
		const { absencesMode } = this.state
		if (!absencesMode) {
			this.props.onSelectedDate(date)
		}
		else if (dateIsGreaterThanOrEqual(date, startOfDay(new Date()))) {
			this.handleAbsencePicked(date)
		}
	}

	handleMonthChange = (date: Date) => {
		this.setState({
			month: date
		}, () => {
			const { month, institution, professional } = this.state
			this.subscribeAppointmentsForMonth(month, institution, professional)
		})
	}

	handleAbsencePicked = (date: Date) => {
		var absenceDatesTemp = this.state.absenceDatesTemp
		const found = absenceDatesTemp.find((d) => {
			return isSame(d, date)
		})

		if (found) {
			absenceDatesTemp = absenceDatesTemp.filter((d) => {
				return !isSame(d, date)
			})
		}
		else {
			absenceDatesTemp.push(date)
		}

		this.setState({
			absenceDatesTemp
		})
	}

	markedDates = () => {
		const { absenceDatesTemp, month, monthAppointments, professional, institution } = this.state
		const marked = {}

		const dotInLine = { key: "inLine", color: "orange" }
		const dotCancelled = { key: "cancelled", color: "red" }
		const dotCompleted = { key: "completed", color: "green" }

		// month appointments
		Object.keys(monthAppointments).forEach((date) => {
			const day = monthAppointments[date]
			const dots = []

			if (day.inLine)
				dots.push(dotInLine)
			if (day.cancelled)
				dots.push(dotCancelled)
			if (day.completed)
				dots.push(dotCompleted)

			marked[date] = {
				dots
			}
		})

		// selected professional absences
		var selectedProfessional = professional

		var absences = []
		if (selectedProfessional && selectedProfessional.absences && institution) {
			absences = selectedProfessional.absences[institution.id] || []
		}

		const old = absences.filter((a) => {
			return !absenceDatesTemp.find((b) => {
				return isSame(a, b)
			})
		})

		const temp = absenceDatesTemp.filter((a) => {
			return !absences.find((b) => {
				return isSame(a, b)
			})
		})

		const absencesMonth: Array<Date> = old.concat(temp).filter((d) => {
			return isSameMonth(d, month)
		})

		return { marked, absences: absencesMonth }
	}

	confirmAbsences = () => {
		const { absenceDatesTemp, professional, institution } = this.state

		// selected professional absences
		var selectedProfessional = professional

		var absences = []
		if (selectedProfessional && selectedProfessional.absences && institution) {
			absences = selectedProfessional.absences[institution.id] || []
		}

		const old = absences.filter((a) => {
			return !absenceDatesTemp.find((b) => {
				return isSame(a, b)
			})
		})

		const temp = absenceDatesTemp.filter((a) => {
			return !absences.find((b) => {
				return isSame(a, b)
			})
		})

		// update firebase
		selectedProfessional.ref.update({
			["absences." + institution.id]: old.concat(temp)
		})

		this.disableAbsences()
	}

	disableAbsences = () => {
		this.setState({
			absencesMode: false,
			absenceDatesTemp: []
		})
	}

	enableAbsences = () => {
		this.setState({
			absencesMode: true
		})
	}

	renderDay = (date: Date, marked: any) => {
		const day = date.getDate()
		const shortDate = formatYYYYMMDD(date)

		const dateStyle = {
			fontSize: 14
		}

		const cellStyle = {
			height: 27,
			width: 27,
			position: "relative"
		}

		var circleStyle = (color: any) => {
			return {
				margin: 1,
				marginBottom: 4,
				padding: 2,
				display: "inline-block",
				backgroundColor: color,
				borderRadius: "50%",
				width: 1,
				height: 1
			}
		}

		return (
			<div style={cellStyle} >
				<div style={dateStyle}>{day}</div>
				{marked[shortDate] &&
					marked[shortDate].dots.map((dot, i) => (
						<div key={i} style={circleStyle(dot.color)} />
					))
				}
			</div >
		)
	}

	render() {
		const {
			absencesMode,
			date,
			month,
			addMode
		} = this.state

		const {
			absences,
			marked
		} = this.markedDates()

		const modifiersStyles = {
			absences: {
				backgroundColor: "red",
				color: "white"
			}
		}

		return (
			<div>
				<div>
					<DayPicker
						locale="es"
						localeUtils={MomentLocaleUtils}
						initialMonth={month}
						month={month}
						onDayClick={this.handleDatePicked}
						selectedDays={date}
						modifiers={{ absences: absences }}
						modifiersStyles={modifiersStyles}
						onMonthChange={this.handleMonthChange}
						renderDay={(day) => this.renderDay(day, marked)}
						disabledDays={addMode || absencesMode ? [{
							before: new Date()
						}] : []}
					/>
				</div>
				<div className="todayButton" onClick={this.showToday} >
					{"Hoy"}
				</div>

				{
					!addMode &&
					<div className="absences">
						<div className="absences-buttons">
							{absencesMode &&
								<div className="absences-button" onClick={this.disableAbsences}>
									<label>{"Cancelar"}</label>
								</div>
							}
							<div className="absences-button" onClick={absencesMode ? this.confirmAbsences : this.enableAbsences}>
								<label>{absencesMode ? "Confirmar" : "Ausencias"}</label>
							</div>
						</div>
					</div>
				}

				{absencesMode &&
					<div className="absences-alert">
						<div>{"Modifique los días que estará ausente."}</div>
						<div>{"Al confirmar las ausencias, las citas programadas para los días seleccionados serán finalizadas autómaticamente."}</div>
					</div>
				}

				<div className="currentDate">
					{formatDateFull(date)}
				</div>
			</div >
		)
	}
}