// @flow

import { addMinutes, dateIsGreaterThanOrEqual, formatDateFull, formatTime, isBeforeToday, isToday, setTime } from "../utils/date"
import { defaultStartTime, finalizedStatuses, Status } from "../constants/values"

const findAppointmentIndex = (appointmentId: any, appointments: Array<any>) => {
	const findAppointment = (a) => {
		return a.id === appointmentId
	}

	return appointments.filter(app => !finalizedStatuses.includes(app.status)).findIndex(findAppointment)
}

const metadata = (appointment: any, professional: any, lastCompletionDate: ?Date, appointments: Array<any>) => {
	var workStartTime = defaultStartTime

	if (appointment.institution && professional.startTime) {
		const id = appointment.institution.id
		workStartTime = professional.startTime[id] || defaultStartTime
	}

	const turn = findAppointmentIndex(appointment.id, appointments)
	const workStartDateAndTime = lastCompletionDate || setTime(appointment.date, workStartTime)
	const today = isToday(appointment.date)
	const inThePast = isBeforeToday(appointment.date)
	const fullDate = formatDateFull(appointment.date)

	const workBegun = dateIsGreaterThanOrEqual(new Date(), workStartDateAndTime)
	const estimatedMinutes = (turn * professional.appointmentDuration)
	const estimatedDateAndTime = addMinutes(workStartDateAndTime, estimatedMinutes)

	const formatedTime = formatTime(estimatedDateAndTime)
	const pastStartTime = dateIsGreaterThanOrEqual(new Date(), addMinutes(workStartDateAndTime, turn === 0 ? professional.appointmentDuration : estimatedMinutes))

	const pastFormatedTime = formatTime(addMinutes(new Date(), estimatedMinutes))
	return {
		turn,
		workStartTime,
		workStartDateAndTime,
		today,
		inThePast,
		fullDate,
		workBegun,
		estimatedMinutes,
		estimatedDateAndTime,
		formatedTime,
		pastStartTime,
		pastFormatedTime
	}
}

const changeStatus = (appointment: any, nextStatus: string) => {
	appointment.ref.update({ status: nextStatus })

	if (nextStatus === Status.Accepted) {
		appointment.professional.update(
			{
				["clients." + appointment.user.id]: true
			}
		)
	}
	else if (nextStatus === Status.InProgress) {
		appointment.ref.update({ startDate: new Date() })
	}
}

const setCompleted = (appointment: any, nextStatus: string) => {
	appointment.ref.update({
		status: nextStatus,
		completionDate: new Date()
	})
}

export default {
	metadata,
	changeStatus,
	setCompleted
}