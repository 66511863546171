// @flow

import React from "react"
import ImageUploader from "react-images-upload"
import Lightbox from "react-images"

import Storage from "../../utils/storage"
import { uuid } from "../../utils/uuid"

type Props = {
	appointment: any
}

type LocalState = {
	appointment: any,
	tests: Array<any>,
	images: Array<any>,
	lightboxIsOpen: boolean,
	currentImage: number
}

export default class Tests extends React.PureComponent<Props, LocalState> {
	unsubscribeTests: Function

	check_images = []

	constructor(props: Props) {
		super(props)

		this.state = {
			appointment: props.appointment,
			tests: [],
			images: [],
			lightboxIsOpen: false,
			currentImage: 0
		}
	}

	componentDidMount() {
		const { appointment } = this.state
		this.subscribe(appointment)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.appointment !== nextProps.appointment) {
			this.setState({
				appointment: nextProps.appointment
			})

			this.subscribe(nextProps.appointment)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(appointment: any) {
		this.unsubscribe()
		this.unsubscribeTests = appointment.ref.collection("tests").orderBy("creationDate").onSnapshot(this.onTestsUpdate)
	}

	unsubscribe() {
		if (this.unsubscribeTests)
			this.unsubscribeTests()
	}

	onTestsUpdate = (snapshot: any) => {
		const tests = []
		snapshot.forEach((doc) => {
			const { image, creationDate } = doc.data()

			tests.push({
				ref: doc.ref,
				id: doc.id,
				creationDate,
				image
			})
		})

		this.setState({
			tests
		}, () => {
			this.getImagesFromFirebase(tests)
		})

		console.warn("TESTS SUB 1")
	}

	getImagesFromFirebase = (tests: any) => {
		const imagesPromises = []
		tests.forEach((test) => {
			imagesPromises.push(Storage.getFileSourceFromFirebase("tests", test.image, test))
		})

		Promise.all(imagesPromises).then((images) => {
			this.setState({
				images
			})
		})
	}

	onAdd = (pictureFiles: Array<any>, pictureDataURLs: Array<any>) => {
		const { appointment } = this.state
		const testsRef = appointment.ref.collection("tests")

		if (pictureFiles.length > 0) {
			let index = pictureFiles.length - 1
			let file = pictureFiles[index]
			if (this.check_images.includes(file)) {
				return
			}
			this.check_images.push(file)

			const fileName = uuid()

			Storage.uploadImageToFirebase("tests", file, fileName)
				.then(() => {
					testsRef.add({
						creationDate: new Date(),
						image: fileName
					})
				}).catch((error) => {
					console.error("error", error)
				})
		}
	}

	removeImage = (image: any) => {
		this.onClickNext()
		const test = image.obj
		test.ref.delete()
		image.ref.delete()
	}

	openLightbox = (index: number) => {
		this.setState({
			lightboxIsOpen: true,
			currentImage: index
		})
	}

	closeLightbox = () => {
		this.setState({
			lightboxIsOpen: false
		})
	}

	onClickNext = () => {
		const { currentImage, images } = this.state

		if (currentImage < images.length - 1) {
			this.setState({
				currentImage: currentImage + 1
			})
		}
		else {
			this.setState({
				currentImage: images.length - 1
			})
		}
	}

	onClickPrev = () => {
		const { currentImage } = this.state

		if (currentImage > 0) {
			this.setState({
				currentImage: currentImage - 1
			})
		}
	}

	render() {
		const { currentImage, images } = this.state

		return (
			<div className="tests">
				<div className="appointmentLabel">Laboratorios</div>
				<div>
					{
						images.map((image, index) => {
							return (
								<div className="thumbnail"
									key={index}
								>
									<img alt={" "} src={image.src} onClick={() => this.openLightbox(index)} />
									<button className="btn" onClick={() => this.removeImage(image)}>Eliminar</button>
								</div>
							)
						})
					}
				</div>

				<ImageUploader
					withIcon={true}
					buttonText="Agregar Imagen"
					onChange={this.onAdd}
					imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
					maxFileSize={5242880}
					buttonClassName={"upload-button"}
					label="Tamaño máximo: 5MB, aceptados: jpeg|gif|png"
				/>

				<Lightbox
					images={images}
					backdropClosesModal
					currentImage={currentImage}
					isOpen={this.state.lightboxIsOpen}
					onClose={this.closeLightbox}
					onClickNext={this.onClickNext}
					onClickPrev={this.onClickPrev}
				/>
			</div >
		)
	}
}