// @flow 

import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"

import * as ROUTES from "../../constants/routes"
import History from "../History"

import Clients from "./clients"
import Client from "./client"

type Props = {
	history: any,
	user: any
}

type LocalState = {
	user: any,
	client: any
}

class ClientsPage extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: undefined
		}
	}

	componentDidMount() {
		if (!this.props.user) {
			this.props.history.push(ROUTES.LOGIN)
			return
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
	}

	onSelectedClient = (client: any) => {
		this.setState({
			client
		})
	}

	render() {
		return (
			<div className="clients">
				<div className="clients-sidebar">
					<div className="content">
						<div className="box">
							<Link to={ROUTES.CLIENT} className="client-link" >Crear Cliente</Link>
							<Clients user={this.state.user} onSelectedClient={this.onSelectedClient} />
						</div>
					</div>
				</div>

				<div className="column">
					<div className="content">
						<div className="box">
							<Client user={this.state.user} client={this.state.client} />
						</div>
					</div>
				</div>
				<div className="column">
					<div className="content">
						<div className="box">
							<History user={this.state.user} client={this.state.client} />
						</div>
					</div>
				</div>
			</div >
		)
	}
}

export default withRouter(ClientsPage)