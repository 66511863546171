// @flow

import * as React from "react"
import Lightbox from "react-images"
import { DateTimePicker } from "react-widgets"

import firebase from "../../utils/firebase"
import { calculateAge, formatDateMedium, startOfDay, parseDate } from "../../utils/date"
import validate from "../../utils/validation"
import { noInsuranceKey } from "../../constants/values"

type Props = {
	client: any,
	onNameVisible: ?Function,
	user: any
}

type LocalState = {
	client: any,
	user: any,
	allergies: Array<any>,
	companies: Array<any>,
	idDocumentSource: any,
	insuranceDocumentSource: any,
	healthCompany: any,
	idDocumentLightboxIsOpen: boolean,
	insuranceDocumentLightboxIsOpen: boolean,
	editable: boolean,
	editing: boolean,
	error: any,
	newDisplayName: ?string,
	newDateOfBirth: ?Date,
	newIdDocument: ?string,
	newGender: ?string,
	newAddress: ?string,
	newPhoneNumber: ?string,
	newEmail: ?string,
	newHealthInsurance: ?string,
	newHealthInsuranceKey: ?string,
	newHealthInsuranceId: ?string,
	dateTimePickerVisible: boolean
}

export default class ClientDetails extends React.Component<Props, LocalState> {
	unsubscribe: Function
	unsubscribeHealthCompany: Function
	unsubscribeAllergies: Function
	unsubscribeHealthCompanies: Function

	static defaultProps = {
		onNameVisible: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			client: undefined,
			user: props.user,
			allergies: [],
			companies: [],
			idDocumentSource: undefined,
			insuranceDocumentSource: undefined,
			healthCompany: undefined,
			idDocumentLightboxIsOpen: false,
			insuranceDocumentLightboxIsOpen: false,
			editable: false,
			editing: false,
			error: {},
			newDisplayName: "",
			newDateOfBirth: undefined,
			newIdDocument: "",
			newGender: "",
			newAddress: "",
			newPhoneNumber: "",
			newEmail: "",
			newHealthInsurance: "",
			newHealthInsuranceKey: "",
			newHealthInsuranceId: "",
			dateTimePickerVisible: false
		}

		this.getClientDetails(props.client)
		this.subscribe()
	}

	UNSAFE_componentWillMount() {
		const allergiesRef = firebase.firestore().collection("allergies")
		this.unsubscribeAllergies = allergiesRef.onSnapshot(this.onAllergiesUpdate)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
		if (this.props.client !== nextProps.client) {
			this.setState({
				client: nextProps.client,
				idDocumentSource: undefined,
				insuranceDocumentSource: undefined,
				healthCompany: undefined,
				editing: false,
				editable: false,
				error: {},
				newDisplayName: "",
				newDateOfBirth: undefined,
				newIdDocument: "",
				newGender: "",
				newAddress: "",
				newPhoneNumber: "",
				newEmail: "",
				newHealthInsurance: "",
				newHealthInsuranceKey: "",
				newHealthInsuranceId: "",
				dateTimePickerVisible: false
			})
			this.getClientDetails(nextProps.client)
		}
	}

	componentWillUnmount() {
		if (this.unsubscribe)
			this.unsubscribe()

		if (this.unsubscribeHealthCompany)
			this.unsubscribeHealthCompany()

		if (this.unsubscribeAllergies)
			this.unsubscribeAllergies()

		if (this.unsubscribeHealthCompanies)
			this.unsubscribeHealthCompanies()
	}

	subscribe() {
		const healthCompaniesRef = firebase.firestore().collection("healthCompanies").orderBy("name")
		this.unsubscribeHealthCompanies = healthCompaniesRef.onSnapshot(this.onHealthCompaniesUpdate)
	}

	getClientDetails(client: any) {
		if (this.unsubscribe)
			this.unsubscribe()

		if (this.unsubscribeHealthCompany)
			this.unsubscribeHealthCompany()

		if (client && client.id) {
			this.unsubscribe = firebase.firestore().collection("users").doc(client.id)
				.onSnapshot(this.onClientUpdate)
			this.loadFirebaseImageURLs(client.id)
		}
	}

	onClientUpdate = (doc: any) => {
		let client = undefined
		if (doc != null) {
			const { displayName, dateOfBirth, idDocument, gender, address, phoneNumber, email, allergies, healthInsurance, healthInsuranceId, createdBy } = doc.data()

			client = {
				id: doc.id,
				ref: doc.ref,
				displayName,
				dateOfBirth,
				age: calculateAge(dateOfBirth),
				idDocument,
				gender,
				address,
				phoneNumber,
				email,
				healthInsuranceKey: healthInsurance.id,
				healthInsuranceId,
				allergies,
				createdBy
			}
			console.log("EMAIL", email)

			this.setState({
				client
			}, () => this.setValues())

			if (healthInsurance) {
				this.unsubscribeHealthCompany = healthInsurance.onSnapshot(this.onHealthCompanyUpdate)
			}

			this.onNameVisible()
		}

		this.checkEditable(client)
		console.warn("CLIENT DETAIL SUB 2")
	}

	onAllergiesUpdate = (querySnapshot: any) => {
		const allergies = []
		querySnapshot.forEach((doc) => {
			const { name } = doc.data()
			allergies.push({
				id: doc.id,
				name
			})
		})
		this.setState({
			allergies
		})

		console.warn("CLIENT DETAIL SUB 3")
	}

	onHealthCompaniesUpdate = (querySnapshot: any) => {
		const companies = []
		querySnapshot.forEach((doc) => {
			const { name } = doc.data()
			companies.push({
				key: doc.id,
				doc, // DocumentSnapshot
				name
			})
		})
		this.setState({
			companies
		})

		console.warn("ADD CLIENT SUB 1")
	}

	onHealthCompanyUpdate = (doc: any) => {
		const { name } = doc.data()

		this.setState({
			healthCompany: {
				id: doc.id,
				ref: doc.ref,
				name
			}
		})

		console.warn("CLIENT DETAIL SUB 4")
	}

	onNameVisible = () => {
		if (this.props.onNameVisible) {
			this.props.onNameVisible(this.state.client.displayName)
		}
	}

	getAllergiesNames() {
		const { allergies } = this.state.client
		var allergiesNames = []

		if (allergies) {
			allergies.forEach((allergy) => {
				var found = this.state.allergies.find((a) => {
					return a.id === allergy.id
				})

				if (found) {
					allergiesNames.push(found.name)
				}
			})
		}

		return allergiesNames.sort().join(", ")
	}

	loadFirebaseImageURLs = (id: string) => {
		const idRef = firebase.storage().ref("id").child(id)
		const insuranceRef = firebase.storage().ref("insurance").child(id)

		idRef.getDownloadURL()
			.then((url) => {
				this.setState({
					idDocumentSource: url
				})
			}).catch(() => {
				this.setState({
					idDocumentSource: ""
				})
			})

		insuranceRef.getDownloadURL()
			.then((url) => {
				this.setState({
					insuranceDocumentSource: url
				})
			})
			.catch(() => {
				this.setState({
					insuranceDocumentSource: ""
				})
			})
	}

	closeLightbox = () => {
		this.setState({
			idDocumentLightboxIsOpen: false,
			insuranceDocumentLightboxIsOpen: false
		})
	}

	checkEditable = (client: any) => {
		var editable = false
		if (client && (client.id ? client.id.length === 20 : false || client.createdBy === this.state.user.ref)) {
			editable = true
		}
		this.setState({
			editable
		})
	}

	validateClient() {
		this.setState({
			error: {}
		})

		var error = {
			newDisplayName: validate("name", this.state.newDisplayName),
			newDateOfBirth: validate("dateOfBirth", this.state.newDateOfBirth),
			newGender: validate("gender", this.state.newGender),
			newIdDocument: validate("idDocument", this.state.newIdDocument),
			newAddress: validate("address", this.state.newAddress),
			newPhoneNumber: validate("phoneNumber", this.state.newPhoneNumber),
			newEmail: this.state.newEmail && this.state.newEmail.trim().length > 0 ? validate("email", this.state.newEmail) : undefined,
			newHealthInsuranceKey: validate("insuranceKey", this.state.newHealthInsuranceKey),
			newHealthInsuranceId: this.state.newHealthInsuranceKey === noInsuranceKey ? undefined : validate("insuranceId", this.state.newHealthInsuranceId)
		}

		this.setState({
			error
		})

		if (
			error.newDisplayName ||
			error.newDateOfBirth ||
			error.newGender ||
			error.newIdDocument ||
			error.newAddress ||
			error.newPhoneNumber ||
			error.newEmail ||
			error.newHealthInsuranceKey ||
			error.newHealthInsuranceId
		) {
			return false
		}
		return true
	}

	updateClient = () => {
		if (this.validateClient()) {
			this.setState({
				editing: false
			})

			const healthInsurance = firebase.firestore().collection("healthCompanies").doc(this.state.newHealthInsuranceKey)

			this.state.client.ref.update({
				displayName: this.state.newDisplayName,
				dateOfBirth: startOfDay(this.state.newDateOfBirth),
				idDocument: this.state.newIdDocument,
				gender: this.state.newGender,
				phoneNumber: this.state.newPhoneNumber,
				email: this.state.newEmail || null,
				address: this.state.newAddress,
				healthInsurance,
				healthInsuranceId: this.state.newHealthInsuranceId || null,
				createdBy: this.state.user.ref
			})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	setValues = () => {
		this.setState({
			newDisplayName: this.state.client.displayName,
			newIdDocument: this.state.client.idDocument,
			newGender: this.state.client.gender,
			newAddress: this.state.client.address,
			newPhoneNumber: this.state.client.phoneNumber,
			newEmail: this.state.client.email,
			newHealthInsurance: this.state.client.healthInsurance,
			newHealthInsuranceKey: this.state.client.healthInsuranceKey,
			newHealthInsuranceId: this.state.client.healthInsuranceId,
			newDateOfBirth: this.state.client.dateOfBirth
		})

		console.log("SET VALUES EMAIL", this.state.newEmail)
	}

	edit = () => {
		this.setState({
			editing: true,
			error: {}
		})
	}

	cancelEdit = () => {
		this.setState({
			editing: false,
			error: {}
		})

		this.setValues()
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onChangeSelectedDay = (newDateOfBirth: Date) => {
		this.setState({
			newDateOfBirth
		})
	}

	onChangeSelectedGender = (event: any) => {
		const newGender = event.target.value

		this.setState({
			newGender
		})
	}

	onChangeSelectedInsurance = (event: any) => {
		const newInsuranceKey = event.target.value

		if (newInsuranceKey) {
			this.setState({
				newHealthInsuranceKey: newInsuranceKey
			})
		}

		if (newInsuranceKey === noInsuranceKey) {
			this.setState({
				newHealthInsuranceId: undefined
			})
		}
	}

	render() {
		const { client, companies, editable, editing, error, healthCompany, idDocumentSource, insuranceDocumentSource } = this.state

		const editableFieldClassname = editing ? "new-client-item-input" : "itemValue"
		const ageLabel = client && client.age === 1 ? "año" : "años"
		return (
			<div className="client-details">
				{editable &&
					<div className="buttons-row">
						{editing ?
							<div >
								<button onClick={this.updateClient}>Guardar</button>
								<button className="cancel" onClick={this.cancelEdit}>Cancelar</button>
							</div>
							:
							<button onClick={this.edit}>Editar Cliente</button>
						}
						<hr />
					</div>
				}

				<div>
					<div className="itemLabel">Nombre</div>

					<input
						className={editableFieldClassname}
						type="text"
						name="newDisplayName"
						placeholder="Ej. Juan Pérez"
						onChange={this.handleChange}
						disabled={!editing}
						value={this.state.newDisplayName}
					/>
					{error.newDisplayName &&
						<div className="new-client-error-label">{error.newDisplayName}</div>
					}
				</div>

				<hr />

				<div>
					<div className="itemLabel">Fecha de Nacimiento</div>
					{editing ?
						<DateTimePicker
							containerClassName="new-client-item-input"
							culture="es"
							value={this.state.newDateOfBirth}
							onChange={this.onChangeSelectedDay}
							placeholder="Ej. 1970-01-31 (año-mes-dia)"
							time={false}
							editFormat={"YYYY-MM-DD"}
							parse={str => parseDate(str)}
							format="dddd, D [de] MMMM, YYYY"
						/>

						:
						<div className="itemValue">
							{client ? formatDateMedium(client.dateOfBirth) + " - " + client.age + " " + ageLabel : " "}
						</div>
					}

					{error.newDateOfBirth &&
						<div className="new-client-error-label">{error.newDateOfBirth}</div>
					}

				</div>

				<hr />

				<div>
					<div>
						<div className="itemLabel">Documento de identidad</div>
						<input
							className={editableFieldClassname}
							type="text"
							name="newIdDocument"
							placeholder="00000000000"
							onChange={this.handleChange}
							disabled={!editing}
							value={this.state.newIdDocument}
						/>
						{error.newIdDocument &&
							<div className="new-client-error-label">{error.newIdDocument}</div>
						}
					</div>
					{idDocumentSource &&
						<div onClick={() => this.setState({ idDocumentLightboxIsOpen: true })}>
							<img
								src={idDocumentSource}
								alt="Documento de Identidad"
								height={60}
								width={60} />
						</div>
					}
				</div>

				<hr />

				<div>
					<div className="itemLabel">Sexo</div>

					{editing ?
						<select onChange={this.onChangeSelectedGender}
							className="new-client-item-input">
							<option value="Femenino" selected={client && client.gender == "Femenino"}>Femenino</option>
							<option value="Masculino" selected={client && client.gender == "Masculino"}>Masculino</option>
						</select>
						:
						<div className="itemValue">
							{client ? client.gender : " "}
						</div>
					}
					{error.newGender &&
						<div className="new-client-error-label">{error.newGender}</div>
					}

				</div>

				<hr />

				<div>
					<div className="itemLabel">Dirección</div>
					<input
						className={editableFieldClassname}
						type="text"
						name="newAddress"
						placeholder="Calle, Casa, Sector, Ciudad"
						onChange={this.handleChange}
						disabled={!editing}
						value={this.state.newAddress}
					/>
					{error.newAddress &&
						<div className="new-client-error-label">{error.newAddress}</div>
					}
				</div>

				<hr />

				<div>
					<div>
						<div className="itemLabel">Número de teléfono</div>
						<input
							className={editableFieldClassname}
							type="text"
							name="newPhoneNumber"
							placeholder="8095555555"
							onChange={this.handleChange}
							disabled={!editing}
							value={this.state.newPhoneNumber}
							mask={"([000]) [000]-[0000]"}
						/>
						{error.newPhoneNumber &&
							<div className="new-client-error-label">{error.newPhoneNumber}</div>
						}
					</div>
				</div>

				<hr />

				<div>
					<div className="itemLabel">Email</div>

					<input
						className={editableFieldClassname}
						type="text"
						name="newEmail"
						placeholder=""
						onChange={this.handleChange}
						disabled={!editing}
						value={this.state.newEmail}
					/>
					{error.newEmail &&
						<div className="new-client-error-label">{error.newEmail}</div>
					}
				</div>

				<hr />

				<div>
					<div className="itemLabel">Alergias</div>
					<div className="itemValue">{client && client.allergies ? this.getAllergiesNames() : "No hay alergias especificadas"}</div>
				</div>

				<hr />

				<div>
					<div className="itemLabel">Administradora de Riesgos de Salud</div>
					{editing ?
						<select onChange={this.onChangeSelectedInsurance}
							className="new-client-item-input">
							<option value={undefined}></option>
							{
								companies.map((company) => {
									const selected = company.key === this.state.newHealthInsuranceKey
									return (
										<option key={company.key} value={company.key} selected={selected}>{company.name}</option>
									)
								})
							}
						</select>
						:
						<div className="itemValue">{healthCompany ? healthCompany.name : " "}</div>
					}
					{error.newHealthInsuranceKey &&
						<div className="new-client-error-label">{error.newHealthInsuranceKey}</div>
					}
				</div>

				{this.state.newHealthInsuranceKey && this.state.newHealthInsuranceKey !== noInsuranceKey &&
					<div>
						<div>
							<div className="new-client-item-label">Número de seguro médico</div>
							<input
								className={editableFieldClassname}
								type="text"
								name="newHealthInsuranceId"
								placeholder="00000000"
								onChange={this.handleChange}
								disabled={!editing}
								value={this.state.newHealthInsuranceId}
							/>
							{error.newHealthInsuranceId &&
								<div className="new-client-error-label">{error.newHealthInsuranceId}</div>
							}
						</div>
						{insuranceDocumentSource &&
							<div onClick={() => this.setState({ insuranceDocumentLightboxIsOpen: true })}>
								<img
									src={insuranceDocumentSource}
									alt="Documento de Aseguradora"
									height={60}
									width={60} />
							</div>
						}
					</div>
				}

				<Lightbox
					images={[
						{ src: idDocumentSource }
					]}
					isOpen={this.state.idDocumentLightboxIsOpen}
					onClose={this.closeLightbox}
				/>

				<Lightbox
					images={[
						{ src: insuranceDocumentSource }
					]}
					isOpen={this.state.insuranceDocumentLightboxIsOpen}
					onClose={this.closeLightbox}
				/>
			</div >
		)
	}
}