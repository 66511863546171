// @flow

const Status = {
	Accepted: "Aceptada",
	Cancelled: "Cancelada",
	Completed: "Completada",
	InProgress: "En Curso",
	Pending: "Pendiente",
	Rejected: "Rechazada"
}
module.exports = {
	Status,
	defaultStartTime: "0900",
	defaultAppointmentDuration: 5,
	cancelledStatuses: [Status.Cancelled, Status.Rejected],
	completedStatuses: [Status.Completed],
	finalizedStatuses: [Status.Completed, Status.Cancelled, Status.Rejected],
	pendingStatuses: [Status.Pending],
	inLineStatuses: [Status.Pending, Status.Accepted, Status.InProgress],
	noInsuranceKey: "NOINSURANCE",
	pastAppointmentsLimit: 10,
	pastAppointmentsRelatedLimit: 2,
	singleRecord: true,
	faqURL: "https://aledo-firebase.firebaseapp.com/faq_doctor.html",
	privacyPolicyURL: "https://aledo-firebase.firebaseapp.com/privacy_policy_doctor.html"
}