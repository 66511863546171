// @flow 

import React, { Component } from "react"

type Props = {
	history: any
}

class ClientsPage extends Component<Props, void> {
	render() {
		return (
			<div>
				<h1>Page Not Found</h1>
			</div>
		)
	}
}

export default ClientsPage