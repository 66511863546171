// @flow

import * as React from "react"

import firebase from "../../utils/firebase"
import Professional from "../Appointments/professional"
import Calendar from "../Appointments/calendar"
import Appointments from "../../utils/appointments"
import { endOfDay, startOfDay, isBeforeToday, isSame } from "../../utils/date"
import { Status, inLineStatuses } from "../../constants/values"

declare var IntervalID: any

type Props = {
	user: any,
	client: any
}

type LocalState = {
	user: any,
	client: any,
	professional: any,
	institution: any,
	date: Date,
	appointmentType: any,
	showComponent: boolean,
	feedback: ?string,
	absences: Array<any>
}

export default class AddAppointment extends React.Component<Props, LocalState> {
	userRef: any

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: props.client,
			appointmentType: undefined,
			professional: undefined,
			institution: undefined,
			date: new Date(),
			showComponent: false,
			feedback: undefined,
			absences: []
		}
	}

	componentDidMount() {
		const { user } = this.state
		this.subscribe(user)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user,
				feedback: undefined
			})

			this.subscribe(nextProps.user)
		}

		if (this.props.client !== nextProps.client) {
			this.setState({
				client: nextProps.client,
				feedback: undefined
			})
		}
	}

	subscribe(user: any) {
		if (user) {
			this.userRef = firebase.firestore().collection("professionals").doc(user.id)

			this.setState({
				professional: this.userRef
			})
		}

		const appointmentType = firebase.firestore().collection("appointmentTypes").doc("pMgziG0i8zJCJSoKWySp")

		this.setState({
			appointmentType: {
				ref: appointmentType
			}
		})
	}

	changeSelectedInstitution = (institution: any) => {
		this.setState({
			institution,
			feedback: undefined
		})
	}

	changeSelectedProfessional = (professional: any) => {
		this.setState({
			professional,
			feedback: undefined
		})
	}

	changeSelectedDate = (selectedDate: Date) => {
		var date = selectedDate
		if (isBeforeToday(date)) {
			date = new Date()
		}
		this.setState({
			date,
			feedback: undefined
		})
	}

	changeAbsences = (absences: Array<any>) => {
		this.setState({
			absences
		})
	}

	validateDate = (date: Date) => {
		const { absences } = this.state
		const found = absences.find((d) => {
			if (isSame(d, date)) {
				return true
			}
		})
		return found ? false : true
	}

	toggleAdd = () => {
		const showComponent = !this.state.showComponent

		this.setState({
			showComponent,
			feedback: undefined
		})
	}

	addAppointment = () => {
		const { professional, institution, client, date, appointmentType } = this.state

		this.setState({
			feedback: undefined
		})

		if (!this.validateDate(date)) {
			this.setState({
				feedback: "El día seleccionado no es laborable."
			})
			return
		}

		firebase.firestore().collection("appointments")
			.where("user", "==", client.ref)
			.where("professional", "==", professional.ref)
			.where("institution", "==", institution.ref)
			.where("date", ">=", startOfDay(date))
			.where("date", "<=", endOfDay(date))
			.get()
			.then((appointments: any) => {
				const found = (appointments.docs.find((a) => {
					return inLineStatuses.includes(a.data().status)
				}))

				if (found) {
					this.setState({
						feedback: "Ya existe una cita para este dia."
					})
				}
				else {
					const appointmentsRef = firebase.firestore().collection("appointments")
					appointmentsRef.add({
						user: client.ref,
						date: startOfDay(date),
						institution: institution.ref,
						professional: professional.ref,
						type: appointmentType.ref,
						status: Status.Pending,
						creationDate: new Date()
					}).then((appointmentRef) => {
						Appointments.changeStatus({
							ref: appointmentRef,
							professional: professional.ref,
							user: client
						}, Status.Accepted)
						this.setState({
							feedback: "Cita creada."
						})
					}).catch((error) => {
						this.setState({
							feedback: "Se produjo un error al intentar crear la cita."
						})
					})
				}
			})
	}

	render() {
		const { user, professional, institution, date, showComponent, feedback } = this.state

		return (
			<div className="appointment-add">
				<label className="appointment-add-title" onClick={this.toggleAdd}>Agendar cita</label>
				{showComponent &&
					<div className="appointment-add-body">
						<Professional user={user} onSelectedInstitution={this.changeSelectedInstitution} onSelectedProfessional={this.changeSelectedProfessional} />
						<Calendar user={user} professional={professional} institution={institution} date={date} onSelectedDate={this.changeSelectedDate} onAbsencesChanged={this.changeAbsences} addMode />
						<button onClick={this.addAppointment} className="create-button">Crear Cita</button>
						{feedback &&
							<div className="appointment-add-feedback">{feedback}</div>
						}
					</div>
				}
			</div>
		)
	}
}