// @flow

import React from "react"
import { withRouter } from "react-router-dom"
import { DateTimePicker } from "react-widgets"

import firebase from "../../utils/firebase"
import * as ROUTES from "../../constants/routes"
import { startOfDay, parseDate } from "../../utils/date"
import { noInsuranceKey } from "../../constants/values"
import validate from "../../utils/validation"

type Props = {
	history: any,
	user: any,
	editing: boolean
}

type LocalState = {
	user: any,
	companies: Array<any>,
	institutions: Array<any>,
	institutionKeys: Array<any>,
	selectedInstitutionRef: any,
	specialties: Array<any>,
	professionals: Array<any>,
	professionalKeys: Array<any>,
	selectedProfessional: any,
	dateTimePickerVisible: boolean,
	appointmentTypes: Array<any>,
	appointmentTypeKeys: Array<any>,
	selectedAppointmentTypeRef: any,
	description: ?string,
	newDisplayName: ?string,
	newDateOfBirth: ?Date,
	newIdDocument: ?string,
	newGender: ?string,
	newAddress: ?string,
	newPhoneNumber: ?string,
	newEmail: ?string,
	newHealthInsurance: ?string,
	newHealthInsuranceKey: ?string,
	newHealthInsuranceId: ?string,
	dateTimePickerVisibleDoB: boolean,
	error: any,
	isCreatedModalVisible: boolean
}

/**
 * Componente que despliega la vista de Perfil de Usuario
 */
class AddClient extends React.Component<Props, LocalState> {
	unsubscribeHealthCompanies: Function
	createClient: Function

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			companies: [],
			date: new Date(),
			institutions: [],
			institutionKeys: [],
			selectedInstitutionRef: null,
			specialties: [],
			professionals: [],
			professionalKeys: [],
			selectedProfessional: null,
			dateTimePickerVisible: false,
			appointmentTypes: [],
			appointmentTypeKeys: [],
			selectedAppointmentTypeRef: null,
			description: undefined,
			newDisplayName: undefined,
			newDateOfBirth: undefined,
			newIdDocument: undefined,
			newGender: undefined,
			newAddress: undefined,
			newPhoneNumber: undefined,
			newEmail: undefined,
			newHealthInsurance: undefined,
			newHealthInsuranceKey: undefined,
			newHealthInsuranceId: undefined,
			dateTimePickerVisibleDoB: false,
			error: {},
			isCreatedModalVisible: false
		}

		this.createClient = this.createClient.bind(this)
	}

	componentDidMount() {
		this.subscribe()
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
	}

	componentWillUnmount() {
		if (this.unsubscribeHealthCompanies)
			this.unsubscribeHealthCompanies()
	}

	subscribe() {
		const healthCompaniesRef = firebase.firestore().collection("healthCompanies").orderBy("name")
		this.unsubscribeHealthCompanies = healthCompaniesRef.onSnapshot(this.onHealthCompaniesUpdate)
	}

	onHealthCompaniesUpdate = (querySnapshot: any) => {
		const companies = []
		querySnapshot.forEach((doc) => {
			const { name } = doc.data()
			companies.push({
				key: doc.id,
				doc, // DocumentSnapshot
				name
			})
		})
		this.setState({
			companies
		})

		console.warn("ADD CLIENT SUB 1")
	}

	validateClient() {
		this.setState({
			error: {}
		})

		var error = {
			newDisplayName: validate("name", this.state.newDisplayName),
			newDateOfBirth: validate("dateOfBirth", this.state.newDateOfBirth),
			newGender: validate("gender", this.state.newGender),
			newIdDocument: validate("idDocument", this.state.newIdDocument),
			newAddress: validate("address", this.state.newAddress),
			newPhoneNumber: validate("phoneNumber", this.state.newPhoneNumber),
			newEmail: this.state.newEmail && this.state.newEmail.trim().length > 0 ? validate("email", this.state.newEmail) : undefined,
			newHealthInsuranceKey: validate("insuranceKey", this.state.newHealthInsuranceKey),
			newHealthInsuranceId: this.state.newHealthInsuranceKey === noInsuranceKey ? undefined : validate("insuranceId", this.state.newHealthInsuranceId)
		}

		this.setState({
			error
		})

		if (
			error.newDisplayName ||
			error.newDateOfBirth ||
			error.newGender ||
			error.newIdDocument ||
			error.newAddress ||
			error.newPhoneNumber ||
			error.newEmail ||
			error.newHealthInsuranceKey ||
			error.newHealthInsuranceId
		) {
			return false
		}
		return true
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onChangeSelectedGender = (event: any) => {
		const newGender = event.target.value

		this.setState({
			newGender
		})
	}

	onChangeSelectedInsurance = (event: any) => {
		const newInsuranceKey = event.target.value

		if (newInsuranceKey) {
			this.setState({
				newHealthInsuranceKey: newInsuranceKey
			})
		}

		if (newInsuranceKey === noInsuranceKey) {
			this.setState({
				newHealthInsuranceId: undefined
			})
		}
	}

	onChangeSelectedDay = (newDateOfBirth: Date) => {
		this.setState({
			newDateOfBirth
		})
	}

	createClient = (event: any) => {
		if (this.validateClient()) {
			const healthInsurance = firebase.firestore().collection("healthCompanies").doc(this.state.newHealthInsuranceKey)

			firebase.firestore().collection("users").add({
				displayName: this.state.newDisplayName,
				dateOfBirth: startOfDay(this.state.newDateOfBirth),
				idDocument: this.state.newIdDocument,
				gender: this.state.newGender,
				phoneNumber: this.state.newPhoneNumber,
				email: this.state.newEmail || null,
				address: this.state.newAddress,
				healthInsurance,
				healthInsuranceId: this.state.newHealthInsuranceId || null,
				createdBy: this.state.user.ref
			})
				.then((userRef) => {
					return this.state.user.ref.update(
						{
							["clients." + userRef.id]: true
						}
					)
				})
				.then(() => {
					this.props.history.push(ROUTES.CLIENTS)
				})
				.catch((error) => {
					console.error(error)
				})
		}

		event.preventDefault()
	}

	render() {
		const { error, companies, newDateOfBirth } = this.state

		return (
			<div>
				<form onSubmit={this.createClient}>
					<div>
						<div className="new-client-item-label">Nombre</div>
						<input
							className="new-client-item-input"
							type="text"
							name="newDisplayName"
							placeholder="Ej. Juan Pérez"
							onChange={this.handleChange}
							value={this.state.newDisplayName}
						/>
						{error.newDisplayName &&
							<div className="new-client-error-label">{error.newDisplayName}</div>
						}
					</div>
					<div>
						<div className="new-client-item-label">Fecha de nacimiento</div>
						<DateTimePicker
							containerClassName="new-client-item-input"
							culture="es"
							value={newDateOfBirth}
							onChange={this.onChangeSelectedDay}
							placeholder="Ej. 1970-01-31 (año-mes-dia)"
							time={false}
							editFormat={"YYYY-MM-DD"}
							parse={str => parseDate(str)}
							format="dddd, D [de] MMMM, YYYY"
						/>

						{error.newDateOfBirth &&
							<div className="new-client-error-label">{error.newDateOfBirth}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Sexo</div>

						<select onChange={this.onChangeSelectedGender}
							className="new-client-item-input">
							<option value={undefined}></option>
							<option value="Femenino">Femenino</option>
							<option value="Masculino">Masculino</option>
						</select>

						{error.newGender &&
							<div className="new-client-error-label">{error.newGender}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Documento de identidad</div>
						<input
							className="new-client-item-input"
							type="text"
							name="newIdDocument"
							// placeholder="000-0000000-0"
							placeholder="00000000000"
							onChange={this.handleChange}
							value={this.state.newIdDocument}
						/>
						{error.newIdDocument &&
							<div className="new-client-error-label">{error.newIdDocument}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Dirección</div>

						<input
							className="new-client-item-input"
							type="text"
							name="newAddress"
							placeholder="Calle, Casa, Sector, Ciudad"
							onChange={this.handleChange}
							value={this.state.newAddress}
						/>
						{error.newAddress &&
							<div className="new-client-error-label">{error.newAddress}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Número de teléfono</div>

						<input
							className="new-client-item-input"
							type="text"
							name="newPhoneNumber"
							placeholder="8095555555"
							// placeholder="(809) 555-5555"
							onChange={this.handleChange}
							value={this.state.newPhoneNumber}
							mask={"([000]) [000]-[0000]"}
						/>
						{error.newPhoneNumber &&
							<div className="new-client-error-label">{error.newPhoneNumber}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Email</div>

						<input
							className="new-client-item-input"
							type="text"
							name="newEmail"
							placeholder="email@ejemplo.com"
							onChange={this.handleChange}
							value={this.state.newEmail}
						/>
						{error.newEmail &&
							<div className="new-client-error-label">{error.newEmail}</div>
						}
					</div>

					<div>
						<div className="new-client-item-label">Administradora de Riesgos de Salud</div>

						<select onChange={this.onChangeSelectedInsurance}
							className="new-client-item-input">
							<option value={undefined}></option>
							{
								companies.map((company) => {
									return (
										<option key={company.key} value={company.key} >{company.name}</option>
									)
								})
							}
						</select>

						{error.newHealthInsuranceKey &&
							<div className="new-client-error-label">{error.newHealthInsuranceKey}</div>
						}
					</div>

					{this.state.newHealthInsuranceKey && this.state.newHealthInsuranceKey !== noInsuranceKey &&
						<div>
							<div className="new-client-item-label">Número de seguro médico</div>
							<input
								className="new-client-item-input"
								type="text"
								name="newHealthInsuranceId"
								placeholder="00000000"
								onChange={this.handleChange}
								value={this.state.newHealthInsuranceId}
							/>
							{error.newHealthInsuranceId &&
								<div className="new-client-error-label">{error.newHealthInsuranceId}</div>
							}
						</div>
					}

					<button className="create-button">Crear</button>
				</form>
			</div >
		)
	}
}

export default withRouter(AddClient)