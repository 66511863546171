import React from "react"
import ReactDOM from "react-dom"
import moment from "moment"
import momentLocalizer from "react-widgets-moment"
import "moment/locale/es"

import "./styles/main.scss"
import "react-widgets/dist/css/react-widgets.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

moment.locale("es")
momentLocalizer()
ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()