// @flow

import { Status } from "../constants/values"

import firebase, { auth } from "./firebase"

export const login = (email: ?string, password: ?string) => {
	return new Promise((resolve, reject) => {
		return auth.signInWithEmailAndPassword(email, password)
			.then((result) => {
				const user = result.user
				user.id = user.uid
				return onLogin(user)
			})
			.then((user) => {
				resolve(user)
			})
			.catch((e) => {
				reject(e.message ? "Usuario o contraseña inválidos." : e)
			})
	})
}

export const logout = () => {
	return new Promise((resolve, reject) => {
		auth.signOut()
			.then(() => {
				resolve()
			})
			.catch((e) => {
				reject(e)
			})
	})
}

export const onLogin = (user: any) => {
	return new Promise((resolve, reject) => {
		return firebase
			.firestore()
			.collection("professionals")
			.doc(user.id)
			.get()
			.then(snap => {
				if (snap.exists) {
					// registerNotificationToken("professionals", user)
					if (!snap.data().displayName) {
						return Promise.reject("Su perfil aún no ha sido completado.")
					}
					else {
						if (user.emailVerified) {
							const { displayName, status } = snap.data()
							user.name = displayName
							user.ref = snap.ref
							user.status = status

							if (status === Status.Pending) {
								return Promise.reject(`Su cuenta esta en estado ${user.status}. El uso de la app está limitado hasta ser activada por nuestro equipo.`)
							}

							return Promise.resolve(user)
						}
						else {
							return Promise.reject("Su dirección de correo electrónico no esta verificado.")
						}
					}
				}
				else {
					return Promise.reject("Usuario o contraseña inválidos.")
				}
			})
			.then((user) => {
				resolve(user)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export default auth