// @flow

import firebase from "firebase/app"

// import packages
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const config = {
  apiKey: "AIzaSyDjyoO75fr8pGpXaVRlO8iAuZ7GoNzR_uo",
  authDomain: "aledo-firebase.firebaseapp.com",
  databaseURL: "https://aledo-firebase.firebaseio.com",
  projectId: "aledo-firebase",
  storageBucket: "aledo-firebase.appspot.com",
  messagingSenderId: "423266712151"
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const storage = firebase.storage()

export default firebase