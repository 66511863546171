// @flow

import React from "react"

import { Status } from "../../constants/values"

type Props = {
	appointment: any,
	onSetCompleted: ?Function,
	onChangeStatus: ?Function,
	showIfEmpty: boolean
}

type LocalState = {
	appointment: any
}

export default class AppointmentButtons extends React.PureComponent<Props, LocalState> {
	unsubscribeUser: Function

	static defaultProps = {
		showIfEmpty: true
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			appointment: props.appointment
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.appointment !== nextProps.appointment) {
			this.setState({
				appointment: nextProps.appointment
			})
		}
	}

	componentWillUnmount() {
		if (this.unsubscribeUser)
			this.unsubscribeUser()
	}

	onSetCompleted = (appointment: any, status: string) => {
		if (this.props.onSetCompleted) {
			this.props.onSetCompleted(appointment, status)
		}
	}

	onChangeStatus = (appointment: any, newStatus: string) => {
		if (this.props.onChangeStatus) {
			this.props.onChangeStatus(appointment, newStatus)
		}
	}

	render() {
		const { appointment } = this.state
		const Accept = () => {
			return (
				<button onClick={() => this.onChangeStatus(appointment, Status.Accepted)}>
					<label >{"Aceptar"}</label>
				</button >
			)
		}

		const Start = () => {
			return (
				<button onClick={() => this.onChangeStatus(appointment, Status.InProgress)}>
					<label>{"Iniciar"}</label>
				</button >
			)
		}

		const Reject = () => {
			return (
				<button onClick={() => this.onChangeStatus(appointment, Status.Rejected)}>
					<label >{"Rechazar"}</label>
				</button >
			)
		}

		const Complete = () => {
			return (
				<button onClick={() => this.onSetCompleted(appointment, Status.Completed)}>
					<label>{"Completar"}</label>
				</button >
			)
		}

		const Empty = () => {
			return (
				<button>
					<label >{" "}</label>
				</button >
			)
		}

		const { status } = appointment

		if (status === Status.Pending) {
			return (
				<React.Fragment>
					<div className="appointment-buttons">
						<Accept />
						<Reject />
					</div>
				</React.Fragment>
			)
		}
		else if (status === Status.Accepted) {
			return (
				<React.Fragment>
					<div className="appointment-buttons">
						<Start />
						<Reject />
					</div>
				</React.Fragment>
			)
		}
		else if (status === Status.InProgress) {
			return (
				<React.Fragment>
					<div className="appointment-buttons">
						<Complete />
						<Reject />
					</div>
				</React.Fragment>
			)
		}
		else {
			return (
				this.props.showIfEmpty &&
				<React.Fragment>
					<div className="appointment-buttons">
						<Empty />
						<Empty />
					</div>
				</React.Fragment>
			)
		}
	}
}