// @flow

import validatejs from "validate.js"

const constraints = {
	address: {
		presence: {
			allowEmpty: false,
			message: "^Debe introducir una dirección"
		}
	},
	dateOfBirth: {
		presence: {
			message: "^Debe seleccionar una fecha de nacimiento"
		}
	},
	daysMask: {
		presence: {
			message: "^Debe seleccionar al menos un día laborable"
		},
		exclusion: {
			within: { "0000000": "0000000" },
			message: "^Debe seleccionar al menos un día laborable"
		}
	},
	duration: {
		presence: {
			message: "^Debe seleccionar una duración"
		},
		numericality: {
			onlyInteger: true,
			greaterThan: 0
		}
	},
	email: {
		presence: {
			message: "^Debe introducir una dirección de correo."
		},
		email: {
			message: "^Debe introducir una dirección de correo válida."
		}
	},
	gender: {
		presence: {
			message: "^Debe seleccionar sexo "
		}
	},
	idDocument: {
		presence: {
			message: "^Debe introducir un número de documento de identidad"
		},
		format: {
			pattern: /^\d{11}$/,
			message: "^Debe introducir un número de documento de identidad válido"
		}
	},
	institution: {
		presence: {
			message: "^Debe seleccionar una institución"
		}
	},
	insuranceId: {
		presence: {
			message: "^Debe introducir un número de seguro"
		},
		format: {
			pattern: /\d{3,}/,
			message: "^Debe introducir un número de seguro válido"
		}
	},
	insuranceKey: {
		presence: {
			message: "^Debe seleccionar una aseguradora o elegir \"Ninguna\""
		}
	},
	name: {
		presence: {
			message: "^Debe introducir un nombre"
		},
		format: {
			pattern: /^(?:[A-zÀ-ú0-9.]+\s{1}){1}(?:[A-zÀ-ú0-9.]+\s?)+$/,
			message: "^Debe introducir un nombre válido"
		}
	},
	office: {
		presence: {
			message: "^Debe introducir un consultorio"
		}
	},
	password: {
		presence: {
			message: "^Debe introducir una contraseña."
		},
		length: {
			minimum: 5,
			message: "^Su contraseña debe tener al menos 5 caracteres."
		}
	},
	phoneNumber: {
		presence: {
			message: "^Debe introducir un número de teléfono"
		},
		format: {
			pattern: /^\d{10}/,
			message: "^Debe introducir un número de teléfono válido"
		}
	},
	profesional: {
		presence: {
			message: "^Debe seleccionar un profesional"
		}
	},
	specialties: {
		presence: {
			message: "^Debe seleccionar alguna especialidad"
		},
		length: {
			minimum: 1,
			message: "^Debe seleccionar alguna especialidad"
		}
	}
}

export default function validate(fieldName: string, value: any) {
	// Validate.js validates your values as an object
	// e.g. var form = {email: 'email@example.com'}
	var formValues = {}
	formValues[fieldName] = value

	var formFields = {}
	formFields[fieldName] = constraints[fieldName]

	// The formValues and validated against the formFields
	// the variable result hold the error messages of the field
	const result = validatejs(formValues, formFields)

	// If there is an error message, return it!
	if (result) {
		// Return only the field error message if there are multiple
		return result[fieldName][0]
	}

	return null
}