// @flow

import * as React from "react"

import Appointments from "../../utils/appointments"
import { dateIsGreaterThanOrEqual, startOfDay, isSame } from "../../utils/date"
import { finalizedStatuses, inLineStatuses, Status } from "../../constants/values"

import AppointmentItem from "./appointmentItem"

declare var IntervalID: any

type Props = {
	user: any,
	professional: any,
	institution: any,
	date: Date,
	onSelectedAppointment: Function,
	filterFinalized: boolean,
	appointmentFromClient: any,
	monthAppointments: Array<any>
}

type LocalState = {
	user: any,
	appointments: Array<any>,
	professional: any,
	institution: any,
	date: Date,
	filterFinalized: boolean,
	lastCompletionDate: ?Date,
	currentDate: Date,
	calendarVisible: boolean,
	absencesMode: boolean,
	absenceDatesTemp: Array<Date>,
	monthAppointments: Array<any>,
	month: Date,
	selected: any,
	appointmentFromClient: any
}

export default class AppointmentHistory extends React.Component<Props, LocalState> {
	interval: IntervalID

	refreshTime = 10000

	static defaultProps = {
		onSelectedAppointment: undefined,
		filterFinalized: true
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			appointments: [],
			user: props.user,
			professional: props.professional,
			institution: props.institution,
			date: startOfDay(props.date),
			filterFinalized: props.filterFinalized,
			lastCompletionDate: undefined,
			currentDate: new Date(),
			calendarVisible: false,
			absencesMode: false,
			absenceDatesTemp: [],
			monthAppointments: props.monthAppointments,
			month: startOfDay(new Date()),
			selected: undefined,
			appointmentFromClient: props.appointmentFromClient
		}

		if (props.monthAppointments) {
			this.onAppointmentsUpdate(props.monthAppointments)
		}
	}

	componentDidMount() {
		const { date } = this.state
		this.handleDatePicked(date)
		this.interval = setInterval(this.updateCurrentDate.bind(this), this.refreshTime)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}

		if (this.props.date !== nextProps.date) {
			this.handleDatePicked(nextProps.date)
		}

		if (this.props.appointmentFromClient !== nextProps.appointmentFromClient) {
			this.setState({
				appointmentFromClient: nextProps.appointmentFromClient
			}, () => {
				this.selectDefaultAppointment()
			})
		}

		if (this.props.filterFinalized !== nextProps.filterFinalized) {
			this.setState({
				filterFinalized: nextProps.filterFinalized
			})
		}

		if (this.props.monthAppointments !== nextProps.monthAppointments) {
			this.setState({
				monthAppointments: nextProps.monthAppointments
			})

			this.onAppointmentsUpdate(nextProps.monthAppointments)
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	updateCurrentDate() {
		this.setState({
			currentDate: new Date()
		})
	}

	onAppointmentsUpdate = (monthAppointments: Array<any>) => {
		this.setState({
			lastCompletionDate: undefined
		})

		const appointments = []
		monthAppointments.forEach((doc) => {
			const { id, ref, date, status, professional, user, institution, type, description, completionDate } = doc

			if (isSame(date, this.state.date)) {
				var appointment = {
					id,
					ref,
					date,
					professional,
					institution,
					type: type,
					description,
					status,
					user,
					completionDate
				}

				if (status === Status.Completed) {
					var lastCompletionDate = appointment.completionDate
					if (this.state.lastCompletionDate &&
						dateIsGreaterThanOrEqual(this.state.lastCompletionDate, appointment.completionDate)) {
						lastCompletionDate = this.state.lastCompletionDate
					}
					if (dateIsGreaterThanOrEqual(lastCompletionDate, appointment.date)) {
						this.setState({
							lastCompletionDate: lastCompletionDate
						})
					}
				}

				appointments.push(appointment)
			}
		})

		this.setState({
			appointments
		}, () => {
			this.selectDefaultAppointment()
		})
	}

	findAppointmentIndex = (appointmentId: any) => {
		const appointments = this.getListItems()

		const findAppointment = (a) => {
			return a.id === appointmentId
		}

		return appointments.findIndex(findAppointment)
	}

	getInLine = () => {
		var inLine = 0
		this.state.appointments.forEach((appointment) => {
			if (inLineStatuses.includes(appointment.status)) {
				inLine += 1
			}
		})

		return inLine
	}

	handleDatePicked = (date: Date) => {
		this.setState({
			date,
			appointments: []
		}, () => {
			this.onAppointmentsUpdate(this.state.monthAppointments)
		})

		this.openAppointment(undefined)
	}

	openAppointment(appointment: any) {
		this.setState({
			selected: appointment
		})
		this.props.onSelectedAppointment(appointment)
	}

	getListItems = () => {
		const { appointments } = this.state

		const filteredAppointments = []

		appointments.forEach((appointment) => {
			if (!finalizedStatuses.includes(appointment.status) || !this.state.filterFinalized) {
				filteredAppointments.push(appointment)
			}
		})

		return filteredAppointments
	}

	filter = () => {
		const filterFinalized = !this.state.filterFinalized

		this.setState({
			filterFinalized
		}, () => {
			this.selectDefaultAppointment()
		})
	}

	selectDefaultAppointment = () => {
		if (this.getListItems().length === 0) {
			this.openAppointment(undefined)
		}
		else if (this.state.appointmentFromClient) {
			const index = this.findAppointmentIndex(this.state.appointmentFromClient.id)
			if (index > -1) {
				this.openAppointment(this.getListItems()[index])
			}
		}
		else if (!this.state.selected || this.findAppointmentIndex(this.state.selected.id) === -1) {
			this.openAppointment(this.getListItems()[0])
		}
	}

	renderInLine = () => {
		const inLine = this.getInLine()

		if (inLine > 0) {
			return (
				<div className="appointmentsInLine">Citas en Cola: {inLine}</div>
			)
		}
		else {
			return (
				<div className="noAppointments">No tiene citas pendientes para este día</div>
			)
		}
	}

	render() {
		const {
			appointments,
			professional,
			institution,
			lastCompletionDate,
			selected,
			filterFinalized
		} = this.state

		return (
			<div>
				{this.renderInLine()}
				<div className="toggleAppointments" onClick={this.filter}>
					{filterFinalized ? "Mostrar citas finalizadas" : "Ocultar citas finalizadas"}
				</div>
				<ul className="appointmentsList">
					{
						this.getListItems().map((appointment) => {
							return (
								<li key={appointment.id} className={`appointment-item ${selected && selected.id === appointment.id ? "selected" : ""}`} >
									<AppointmentItem
										appointment={appointment}
										onPressItem={(appointment) => this.openAppointment(appointment)}
										onSetCompleted={(appointment, status) => Appointments.setCompleted(appointment, status)}
										onChangeStatus={(appointment, status) => Appointments.changeStatus(appointment, status)}
										institution={institution}
										professional={professional}
										appointments={appointments}
										lastCompletionDate={lastCompletionDate} />
								</li>
							)
						})
					}
				</ul>
			</div >
		)
	}
}