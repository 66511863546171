// @flow

import * as React from "react"
import { withRouter } from "react-router-dom"
import ReactToPrint from "react-to-print"

import firebase from "../../utils/firebase"
import { addDays, dateIsGreaterThanOrEqual, formatDateFull, startOfDay } from "../../utils/date"
import { completedStatuses, finalizedStatuses, pastAppointmentsRelatedLimit } from "../../constants/values"
import ClientDetails from "../Clients/clientDetails"
import History from "../History"

import Notes from "./notes"
import Prescriptions from "./prescriptions"
import Tests from "./tests"
import PrintPrescriptions from "./printPrescriptions"

type Props = {
	user: any,
	appointment: any,
	professional: any,
	institution: any,
	date: Date,
	history: any,
	onAppointmentFromAppointment: Function
}

type LocalState = {
	user: any,
	appointment: any,
	appointments: Array<any>,
	date: Date,
	lastCompletionDate: ?Date,
	professional: any,
	institution: any,
	prescriptions: Array<any>,
	pastAppointments: Array<any>,
	pastNotes: any,
	pastPrescriptions: any
}

class Appointment extends React.Component<Props, LocalState> {
	unsubscribeAppointment: Function
	unsubscribeDayAppointments: Function
	unsubscribePastAppointments: Function

	printComponent: any

	unsubscribePastNotes: Array<Function> = []
	unsubscribePastPrescriptions: Array<Function> = []

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			appointment: props.appointment,
			appointments: [],
			date: props.date,
			lastCompletionDate: undefined,
			professional: props.professional,
			institution: props.institution,
			prescriptions: [],
			pastAppointments: [],
			pastNotes: {},
			pastPrescriptions: {}
		}
	}

	componentDidMount() {
		const { user, appointment, professional, institution, date } = this.state
		this.subscribe(user, appointment, professional, institution, date)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user ||
			this.props.appointment !== nextProps.appointment ||
			this.props.professional !== nextProps.professional ||
			this.props.institution !== nextProps.institution ||
			this.props.date !== nextProps.date) {
			this.setState({
				user: nextProps.user,
				appointment: nextProps.appointment,
				professional: nextProps.professional,
				institution: nextProps.institution,
				date: nextProps.date
			})

			this.unsubscribe()
			this.subscribe(nextProps.user, nextProps.appointment, nextProps.professional, nextProps.institution, nextProps.date)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(user: any, appointment: any, professional: any, institution: any, date: Date) {
		if (appointment) {
			this.unsubscribeAppointment = appointment.ref.onSnapshot(this.onAppointmentUpdate)
			this.subscribeDayAppointments(professional, institution, date)

			this.unsubscribePastAppointments = firebase.firestore().collection("appointments").where("user", "==", appointment.user)
				.where("professional", "==", user.ref)
				.where("date", "<", appointment.date)
				.orderBy("date", "desc")
				.orderBy("creationDate", "desc")
				.onSnapshot(this.onPastAppointments)
		}
	}

	subscribeDayAppointments(professional: any, institution: any, date: Date) {
		if (professional && institution && date) {
			// listen to current appointment day list of appointments for the professional on that institution
			let startDate = startOfDay(date)
			let endDate = addDays(date, 1)

			this.unsubscribeDayAppointments = firebase.firestore().collection("appointments").where("professional", "==", professional.ref)
				.where("institution", "==", institution)
				.where("date", ">=", startDate)
				.where("date", "<", endDate)
				.orderBy("date")
				.orderBy("creationDate")
				.onSnapshot(this.onAppointmentsUpdate)
		}
	}

	unsubscribe() {
		if (this.unsubscribeAppointment)
			this.unsubscribeAppointment()

		if (this.unsubscribeDayAppointments)
			this.unsubscribeDayAppointments()

		if (this.unsubscribePastAppointments)
			this.unsubscribePastAppointments()

		this.unsubscribePastNotes.forEach((unsub) => {
			unsub()
		})

		this.unsubscribePastPrescriptions.forEach((unsub) => {
			unsub()
		})
	}

	onAppointmentUpdate = (doc: any) => {
		const { date, status, user, professional, institution, type, description, completionDate } = doc.data()
		const appointment = {
			id: doc.id,
			ref: doc.ref,
			date,
			user,
			institution,
			professional,
			type,
			description,
			status,
			completionDate
		}

		this.setState({
			appointment
		})

		console.warn("APPOINTMENT DETAIL SUB 3")
	}

	onAppointmentsUpdate = (snapshot: any) => {
		const appointments = []
		snapshot.forEach((doc) => {
			const { status, date, completionDate } = doc.data()
			var appointment = {
				id: doc.id,
				date,
				status,
				completionDate
			}

			if (!finalizedStatuses.includes(status)) {
				appointments.push(appointment)
			}

			if (status === "Completada") {
				var lastCompletionDate = appointment.completionDate
				if (this.state.lastCompletionDate &&
					dateIsGreaterThanOrEqual(this.state.lastCompletionDate, appointment.completionDate)) {
					lastCompletionDate = this.state.lastCompletionDate
				}
				if (dateIsGreaterThanOrEqual(lastCompletionDate, appointment.date)) {
					this.setState({
						lastCompletionDate: lastCompletionDate
					})
				}
			}
		})

		this.setState({
			appointments
		})

		console.warn("APPOINTMENT DETAIL SUB 4")
	}

	onPastAppointments = (snapshot: any) => {
		const pastAppointments = []
		var count = 0
		snapshot.forEach((doc) => {
			const { date, status, professional, user, institution, type, description, completionDate } = doc.data()
			var appointment = {
				id: doc.id,
				ref: doc.ref,
				date,
				professional,
				institution,
				type: type,
				description,
				status,
				user,
				completionDate
			}

			if (count < pastAppointmentsRelatedLimit && completedStatuses.includes(status)) {
				pastAppointments.push(appointment)
				count = count + 1
			}
		})

		this.subscribePastNotes(pastAppointments)
		this.subscribePastPrescriptions(pastAppointments)

		this.setState({
			pastAppointments
		})

		console.warn("CLIENT SUB 2")
	}

	findAppointmentIndex = (appointmentId: any) => {
		const { appointments } = this.state

		const findAppointment = (a) => {
			return a.id === appointmentId
		}

		return appointments.findIndex(findAppointment)
	}

	onUpdatePrescriptions = (prescriptions: Array<any>) => {
		this.setState({
			prescriptions
		})
	}

	onPastNotesUpdate = (snapshot: any) => {
		const notes = []
		var appointment = ""
		snapshot.forEach((doc) => {
			const { id } = doc
			const { note, creationDate } = doc.data()

			appointment = doc.ref.parent.parent.id
			notes.push({
				ref: doc.ref,
				id,
				creationDate,
				note
			})
		})

		this.setState({
			pastNotes: {
				...this.state.pastNotes,
				[appointment]: notes
			}
		})

		console.warn("PAST NOTE SUB 1")
	}

	onPastPrescriptionsUpdate = (snapshot: any) => {
		const prescriptions = []
		var appointment = ""
		snapshot.forEach((doc) => {
			const { id } = doc
			const { prescription, creationDate } = doc.data()

			appointment = doc.ref.parent.parent.id
			prescriptions.push({
				ref: doc.ref,
				id,
				creationDate,
				prescription
			})
		})

		this.setState({
			pastPrescriptions: {
				...this.state.pastPrescriptions,
				[appointment]: prescriptions
			}
		})

		console.warn("PAST PRESCRIPTION SUB 1")
	}

	subscribePastNotes = (pastAppointments: Array<any>) => {
		pastAppointments.forEach((appointment) => {
			this.unsubscribePastNotes.push(appointment.ref.collection("notes").orderBy("creationDate").onSnapshot(this.onPastNotesUpdate))
		})
	}

	subscribePastPrescriptions = (pastAppointments: Array<any>) => {
		pastAppointments.forEach((appointment) => {
			this.unsubscribePastPrescriptions.push(appointment.ref.collection("prescriptions").orderBy("creationDate").onSnapshot(this.onPastPrescriptionsUpdate))
		})
	}

	getPastNotes = (appointment: any) => {
		return this.state.pastNotes[appointment.id] || []
	}

	getPastPrescriptions = (appointment: any) => {
		return this.state.pastPrescriptions[appointment.id] || []
	}

	openAppointment(appointment: any) {
		if (this.props.onAppointmentFromAppointment) {
			this.props.onAppointmentFromAppointment(appointment)
		}
	}

	render() {
		const { appointment, user, prescriptions, pastAppointments } = this.state

		if (!appointment || !appointment.id) {
			return (
				<div className="appointment">
					<div className="column">
						<div className="content">
							<div className="box">
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div className="appointment">
				<div className="column">
					<div className="content">
						<div className="box">
							<ClientDetails client={appointment.user} user={user} />

							<hr className="appointment-divider" />

							{appointment.description &&
								<div>
									<div className="appointmentLabel">Motivo de Consulta</div>
									<div className="appointmentValue">{appointment.description}</div>
								</div>
							}

							<Notes appointment={appointment} />
							<Prescriptions appointment={appointment} onPrescriptionsChange={this.onUpdatePrescriptions} />

							<ReactToPrint
								trigger={() =>
									<div className="notes-form">
										<button className="print-button">Imprimir</button>
									</div>
								}
								content={() => this.printComponent}
							/>

							<div hidden>
								<PrintPrescriptions ref={el => (this.printComponent = el)} prescriptions={prescriptions} />
							</div>
							<Tests appointment={appointment} />

							{pastAppointments.length > 0 &&
								<hr className="appointment-divider" />
							}
							{pastAppointments.length > 0 &&
								<div>
									<div className="appointmentLabel">Ultimas citas</div>

									{pastAppointments.map((appointment) => {
										return (
											<div className="appointment-item" key={appointment.id}
												onClick={() => this.openAppointment(appointment)}>
												<label className="appointment-item-date">
													{formatDateFull(appointment.date)}
												</label>
												<div className="appointment-item-description">
													{appointment.description}
												</div>

												{this.getPastNotes(appointment).length > 0 &&
													<hr />
												}
												<div>
													{this.getPastNotes(appointment).map((note) => {
														return (
															<div className="appointment-item-notes" key={note.id}>
																<div>{note.note}</div>
															</div>
														)
													})}
												</div>

												{this.getPastPrescriptions(appointment).length > 0 &&
													<hr />
												}
												<div>
													{this.getPastPrescriptions(appointment).map((prescription) => {
														return (
															<div className="appointment-item-notes" key={prescription.id}>
																<div>{prescription.prescription}</div>
															</div>
														)
													})}
												</div>
											</div>
										)
									})
									}
								</div>
							}
						</div>
					</div>
				</div>
				<div className="column">
					<div className="content">
						<div className="box">
							<History user={user} client={{ ref: appointment.user }} />
						</div>
					</div>
				</div>
			</div >
		)
	}
}

export default withRouter(Appointment)