// @flow 

import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import * as ROUTES from "../../constants/routes"

import AddClient from "./addClient"

type Props = {
	history: any,
	user: any
}

type LocalState = {
	user: any,
	client: any
}

class NewClientPage extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: undefined
		}
	}

	componentDidMount() {
		if (!this.props.user) {
			this.props.history.push(ROUTES.LOGIN)
			return
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
	}

	onSelectedClient = (client: any) => {
		this.setState({
			client
		})
	}

	render() {
		return (
			<div className="new-client-main">
				<div className="new-client-title">Cliente Nuevo</div>
				<AddClient user={this.state.user} />
			</div>
		)
	}
}

export default withRouter(NewClientPage)