// @flow

import React from "react"

import { singleRecord, Status } from "../../constants/values"
import { formatDateFull, formatTime } from "../../utils/date"

type Props = {
	user: any,
	client: any
}

type LocalState = {
	user: any,
	client: any,
	history: Array<any>,
	pathological: string,
	quirurgical: string,
	drugs: string,
	nonPathological: string,
	hereditary: string,
	length: number,
	currentIndex: ?number,
	currentHistory: ?any
}

export default class BasicHistory extends React.PureComponent<Props, LocalState> {
	unsubscribeHistory: Function

	type = "basic"

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			client: props.client,
			history: [],
			pathological: "",
			quirurgical: "",
			drugs: "",
			nonPathological: "",
			hereditary: "",
			length: 0,
			currentIndex: undefined,
			currentHistory: undefined
		}
	}

	componentDidMount() {
		const { client } = this.state
		this.subscribe(client)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
		if (this.state.client !== nextProps.client) {
			this.setState({
				client: nextProps.client,
				history: [],
				pathological: "",
				quirurgical: "",
				drugs: "",
				nonPathological: "",
				hereditary: "",
				length: 0,
				currentIndex: undefined,
				currentHistory: undefined
			})

			this.subscribe(nextProps.client)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(client: any) {
		if (client) {
			this.unsubscribeHistory = client.ref.collection("history")
				.where("type", "==", this.type)
				.orderBy("creationDate")
				.onSnapshot(this.onHistoryUpdate)
		}
	}

	unsubscribe() {
		if (this.unsubscribeHistory)
			this.unsubscribeHistory()
	}

	onHistoryUpdate = (snapshot: any) => {
		const { user } = this.state
		const history = []
		snapshot.forEach((doc) => {
			const { pathological, quirurgical, drugs, nonPathological, hereditary, creationDate, professional } = doc.data()

			if (singleRecord || professional.id === user.id) {
				history.push({
					ref: doc.ref,
					id: doc.id,
					creationDate,
					pathological,
					quirurgical,
					drugs,
					nonPathological,
					hereditary,
					professional
				})
			}
		})

		const length = history.length
		const currentIndex = length - 1
		const currentHistory = history[currentIndex]

		this.setState({
			history,
			length,
			currentIndex,
			currentHistory,
			pathological: currentHistory ? currentHistory.pathological : "",
			quirurgical: currentHistory ? currentHistory.quirurgical : "",
			drugs: currentHistory ? currentHistory.drugs : "",
			nonPathological: currentHistory ? currentHistory.nonPathological : "",
			hereditary: currentHistory ? currentHistory.hereditary : ""
		})

		console.warn("HISTORY SUB 1")
	}

	addHistory = (event: any) => {
		const { client, pathological, quirurgical, drugs, nonPathological, hereditary, currentHistory, user } = this.state
		const currentPathological = currentHistory ? currentHistory.pathological : ""
		const currentQuirurgical = currentHistory ? currentHistory.quirurgical : ""
		const currentDrugs = currentHistory ? currentHistory.drugs : ""
		const currentNonPathological = currentHistory ? currentHistory.nonPathological : ""
		const currentHereditary = currentHistory ? currentHistory.hereditary : ""

		if ((pathological.trim() !== "" && pathological !== currentPathological) ||
			(quirurgical.trim() !== "" && quirurgical !== currentQuirurgical) ||
			(drugs.trim() !== "" && drugs !== currentDrugs) ||
			(nonPathological.trim() !== "" && nonPathological !== currentNonPathological) ||
			(hereditary.trim() !== "" && hereditary !== currentHereditary)) {
			client.ref.collection("history").add({
				type: this.type,
				pathological,
				quirurgical,
				drugs,
				nonPathological,
				hereditary,
				creationDate: new Date(),
				professional: user.ref
			})
		}

		event.preventDefault()
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	move = (offset: number) => {
		var { pathological, quirurgical, drugs, nonPathological, hereditary, currentHistory, currentIndex, history } = this.state

		currentIndex = currentIndex + offset
		currentHistory = history[currentIndex]
		pathological = currentHistory.pathological ? currentHistory.pathological : ""
		quirurgical = currentHistory.quirurgical ? currentHistory.quirurgical : ""
		drugs = currentHistory.drugs ? currentHistory.drugs : ""
		nonPathological = currentHistory.nonPathological ? currentHistory.nonPathological : ""
		hereditary = currentHistory.hereditary ? currentHistory.hereditary : ""

		this.setState({
			pathological,
			quirurgical,
			drugs,
			nonPathological,
			hereditary,
			currentIndex,
			currentHistory
		})
	}

	render() {
		const { pathological, quirurgical, drugs, nonPathological, hereditary, currentHistory, currentIndex, length, user } = this.state
		const hasPrevious = (currentIndex && currentIndex > 0)
		const hasNext = (currentIndex || 0) < (length - 1)
		const editedByMe = (currentHistory && user ? currentHistory.professional.id === user.id : false)
		const dateTime = currentHistory ? formatDateFull(currentHistory.creationDate) + " " + formatTime(currentHistory.creationDate) : undefined
		const locked = user && user.status !== Status.Accepted

		return (
			<div>
				<form className="notes-form" onSubmit={this.addHistory}>
					<div className="historyLabel">Antecedentes Personales Patológicos</div>
					<textarea
						className="note-text-area"
						type="text"
						multiple={true}
						name="pathological"
						disabled={hasNext || locked}
						onChange={this.handleChange}
						value={pathological}
						placeholder={!hasNext ? "Cardiovasculares, pulmonares, digestivos, diabetes, renales, transfusiones." : ""} />

					<div className="historyLabel">Antecedentes Quirúrgicos</div>
					<textarea
						className="note-text-area"
						type="text"
						multiple={true}
						name="quirurgical"
						disabled={hasNext || locked}
						onChange={this.handleChange}
						value={quirurgical}
						placeholder={!hasNext ? "Operaciones, procedimientos, cesáreas, otros." : ""} />

					<div className="historyLabel">Medicamentos Habituales</div>
					<textarea
						className="note-text-area"
						type="text"
						multiple={true}
						name="drugs"
						disabled={hasNext || locked}
						onChange={this.handleChange}
						value={drugs}
						placeholder={!hasNext ? "Medicamentos que actualmente utiliza para condiciones existentes." : ""} />

					<div className="historyLabel">Antecedentes Personales No Patológicos</div>
					<textarea
						className="note-text-area"
						type="text"
						multiple={true}
						name="nonPathological"
						disabled={hasNext || locked}
						onChange={this.handleChange}
						value={nonPathological}
						placeholder={!hasNext ? "Alcohol, tabaquismo, drogas, inmunizaciones, otros." : ""} />

					<div className="historyLabel">Antecedentes Familiares</div>
					<textarea
						className="note-text-area"
						type="text"
						multiple={true}
						name="hereditary"
						disabled={hasNext || locked}
						onChange={this.handleChange}
						value={hereditary}
						placeholder={!hasNext ? "Posibles enfermedades hereditarias." : ""} />

					{currentHistory &&
						<div className="appointmentValue">Editado {dateTime} {editedByMe ? "por mi" : ""}</div>
					}
					{!locked &&
						<button className="create-button"> Guardar</button>
					}
				</form>
				{
					length > 0 &&
					<div className="history-buttons">
						<button disabled={!hasPrevious} onClick={() => this.move(-1)}>Anterior</button>
						<label>{currentIndex + 1} de {length}</label>
						<button disabled={!hasNext} onClick={() => this.move(1)}> Próximo</button>
					</div>
				}
			</div >
		)
	}
}