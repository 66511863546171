// @flow

import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import LoadingScreen from "react-loading-screen"

import * as ROUTES from "../../constants/routes"
import { auth } from "../../utils/firebase"
import { onLogin } from "../../utils/auth"
import Navigation from "../Navigation"
import Menu from "../Navigation/mobile"
import Login from "../Login"
import Dashboard from "../Dashboard"
import Client from "../Client"
import Clients from "../Clients"
import PageNotFound from "../PageNotFound"
import Appointments from "../Appointments"
import logo from "../../assets/logo.png"

type LocalState = {
  user: any,
  loading: boolean
}
class App extends Component<void, LocalState> {
  constructor() {
    super()

    this.state = {
      user: undefined,
      loading: true
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.id = user.uid
        onLogin(user)
          .then((user) => {
            this.setState({
              user,
              loading: false
            })
          })
          .catch((error) => {
            this.setState({
              user: undefined,
              loading: false
            })
            console.error(error)
          })
      }
      else {
        this.setState({
          user: undefined,
          loading: false
        })
      }
    })
  }

  onLoginComplete = (user: any) => {
    this.setState({
      user
    })
  }

  render() {
    const { loading } = this.state
    return (
      <LoadingScreen
        loading={loading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={logo}
        text="Bienvenido a Aledo Doctor">
        <Router>
          <div>
            <Menu user={this.state.user} />
            <div className="header">
              <Navigation user={this.state.user} />
            </div>
            {/* <hr /> */}
            <div className="main">
              <Switch>
                <Route exact path={ROUTES.HOME} render={props =>
                  <Dashboard {...props} user={this.state.user} />
                } />
                <Route exact path={ROUTES.LOGIN} render={props =>
                  <Login {...props} user={this.state.user} onLoginComplete={this.onLoginComplete} />
                } />
                <Route exact path={ROUTES.DASHBOARD} render={props =>
                  <Dashboard {...props} user={this.state.user} />
                } />
                <Route exact path={ROUTES.CLIENTS} render={props =>
                  <Clients {...props} user={this.state.user} />
                } />
                <Route exact path={ROUTES.CLIENT} render={props =>
                  <Client {...props} user={this.state.user} />
                } />
                <Route exact path={ROUTES.APPOINTMENTS} render={props =>
                  <Appointments {...props} user={this.state.user} />
                } />
                <Route exact path={ROUTES.APPOINTMENT} render={props =>
                  <Appointments {...props} user={this.state.user} />
                } />
                <Route exact path="*" component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </LoadingScreen>
    )
  }
}

export default App