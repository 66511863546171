// @flow

import React from "react"

type Props = {
	appointment: any,
	onPrescriptionsChange: ?Function
}

type LocalState = {
	appointment: any,
	prescriptions: Array<any>,
	prescription: string
}

export default class Prescriptions extends React.PureComponent<Props, LocalState> {
	unsubscribePrescriptions: Function

	static defaultProps = {
		onPrescriptionsChange: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			appointment: props.appointment,
			prescriptions: [],
			prescription: ""
		}
	}

	componentDidMount() {
		const { appointment } = this.state
		this.subscribe(appointment)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.appointment !== nextProps.appointment) {
			this.setState({
				appointment: nextProps.appointment
			})

			this.subscribe(nextProps.appointment)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(appointment: any) {
		this.unsubscribePrescriptions = appointment.ref.collection("prescriptions").orderBy("creationDate").onSnapshot(this.onPrescriptionsUpdate)
	}

	unsubscribe() {
		if (this.unsubscribePrescriptions)
			this.unsubscribePrescriptions()
	}

	onPrescriptionsUpdate = (snapshot: any) => {
		const prescriptions = []
		snapshot.forEach((doc) => {
			const { prescription, creationDate } = doc.data()
			prescriptions.push({
				ref: doc.ref,
				id: doc.id,
				creationDate,
				prescription
			})
		})

		this.setState({
			prescriptions
		})

		console.warn("PRESCRIPTIONS SUB 2")

		if (this.props.onPrescriptionsChange) {
			this.props.onPrescriptionsChange(prescriptions)
		}
	}

	addPrescription = (event: any) => {
		const { appointment, prescription } = this.state

		if (prescription.trim() !== "") {
			appointment.ref.collection("prescriptions").add({
				prescription,
				creationDate: new Date()
			})
		}
		this.setState({
			prescription: ""
		})

		event.preventDefault()
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	removePrescription = (prescription: any) => {
		prescription.ref.delete()
	}

	render() {
		const { prescriptions } = this.state

		return (
			<div>
				<div className="appointmentLabel">Indicaciones</div>
				<form className="notes-form" onSubmit={this.addPrescription}>
					<textarea className="note-text-area" type="text" multiple={true} name="prescription" onChange={this.handleChange} value={this.state.prescription} />
					<button className="create-button">Agregar</button>
				</form>
				<ul className="notesList">
					{prescriptions.map((prescription) => {
						return (
							<li key={prescription.id} className="note-item">
								<div className="note-value">{prescription.prescription}</div>
								<button className="note-remove" onClick={() => this.removePrescription(prescription)}>
									{"Eliminar"}
								</button>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}