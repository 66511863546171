// @flow

import React, { Component } from "react"

import firebase from "../../utils/firebase"
import Sort from "../../utils/sort"

import ClientItem from "./clientItem"

type Props = {
	user: any,
	onSelectedClient: Function
}

type LocalState = {
	user: any,
	clients: Array<any>,
	professional: any,
	selected: any,
	searchFilter: string
}

export default class Clients extends Component<Props, LocalState> {
	ref: any
	unsubscribeProfessional: Function
	unsubscribeClients: Array<Function> = []

	static defaultProps = {
		onSelectedClient: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			clients: [],
			professional: undefined,
			selected: undefined,
			searchFilter: ""
		}
	}

	componentDidMount() {
		const { user } = this.state
		this.subscribeProfessional(user)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})

			this.subscribeProfessional(nextProps.user)
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribeProfessional(user: any) {
		this.unsubscribe()

		if (user && user.id) {
			this.ref = firebase.firestore().collection("professionals").doc(user.id)
			this.unsubscribeProfessional = this.ref.onSnapshot(this.onProfessionalUpdate)
		}
	}

	subscribeClients(clients: any) {
		const clientsIds = Object.keys(clients || {})
		this.unsubscribeClients.forEach((unsub) => {
			unsub()
		})

		this.setState({
			clients: []
		})

		clientsIds.forEach((client) => {
			const clientRef = firebase.firestore().collection("users").doc(client)
			const unsub = clientRef.onSnapshot(this.onClientUpdate)
			this.unsubscribeClients.push(unsub)
		})
	}

	unsubscribe() {
		if (this.unsubscribeProfessional)
			this.unsubscribeProfessional()

		this.unsubscribeClients.forEach((unsub) => {
			unsub()
		})
	}

	onProfessionalUpdate = (doc: any) => {
		const { displayName, clients } = doc.data()

		const professional = {
			ref: doc.ref,
			id: doc.id,
			name: displayName,
			clients
		}

		this.setState({
			professional
		})

		this.subscribeClients(clients)

		console.warn("CLIENTS SUB 1")
	}

	onClientUpdate = (doc: any) => {
		const id = doc.id
		const ref = doc.ref
		const { createdBy, displayName } = doc.data()
		const { clients } = this.state

		const clientsUpdate = []
		var exists = false

		const newClient = {
			ref,
			id,
			name: displayName,
			createdBy
		}

		clients.forEach((client) => {
			if (client.id === id) {
				clientsUpdate.push(newClient)

				exists = true
			}
			else {
				clientsUpdate.push(client)
			}
		})

		if (!exists) {
			clientsUpdate.push(newClient)
		}

		this.setState({
			clients: clientsUpdate.sort(Sort.sortByName)
		})

		console.warn("CLIENTS SUB 2")
	}

	openClient(client: any) {
		this.setState({
			selected: client
		})

		console.log("OPEN ", client.id)

		this.props.onSelectedClient(client)
	}

	handleChange = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	getClients = () => {
		const { clients, searchFilter } = this.state
		return clients.filter((client) => {
			return searchFilter.trim() === "" || client.name.toLowerCase().includes(searchFilter.trim().toLowerCase())
		})
	}

	render() {
		const { selected } = this.state
		return (
			<div>
				<form className="client-filter-form">
					<input type="text" name="searchFilter" className="client-filter" placeholder="Buscar Cliente" onChange={this.handleChange} value={this.state.searchFilter} />
				</form>
				<ul className="clients-list">
					{
						this.getClients().map((client) =>
							<li key={client.id} className={`client-item ${selected && selected.id === client.id ? "selected" : ""}`} >
								<ClientItem
									client={client}
									isProfessional={true}
									onPressItem={(client) => this.openClient(client)} />
							</li>
						)
					}
				</ul>
			</div >
		)
	}
}