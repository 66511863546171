// @flow

import * as React from "react"

import firebase from "../../utils/firebase"

declare var IntervalID: any

type Props = {
	user: any,
	onSelectedProfessional: Function,
	onSelectedInstitution: Function,
	institution: any
}

type LocalState = {
	user: any,
	professional: any,
	institutions: Array<any>,
	institutionList: Array<any>,
	selectedInstitutionRef: any,
	selectedProfessionalRef: any
}

export default class Professional extends React.Component<Props, LocalState> {
	userRef: any

	unsubscribeProfessional: Function
	unsubscribeInstitutions: Function

	static defaultProps = {
		onSelectedAppointment: undefined,
		institution: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			professional: undefined,
			institutions: [],
			institutionList: [],
			selectedInstitutionRef: props.institution ? props.institution.ref : undefined,
			selectedProfessionalRef: undefined
		}
	}

	componentDidMount() {
		const { user } = this.state
		this.subscribe(user)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})

			this.subscribe(nextProps.user)
		}

		if (this.props.institution !== nextProps.institution) {
			this.setState({
				selectedInstitutionRef: nextProps.institution.ref
			})
		}
	}

	componentWillUnmount() {
		this.unsubscribe()
	}

	subscribe(user: any) {
		this.unsubscribe()
		if (user) {
			this.userRef = firebase.firestore().collection("professionals").doc(user.id)

			this.unsubscribeInstitutions = firebase.firestore().collection("institutions").onSnapshot(this.onInstitutionsUpdate)

			this.unsubscribeProfessional = this.userRef.onSnapshot(this.onProfessionalUpdate)
			this.setState({
				selectedProfessionalRef: this.userRef
			})
		}
	}

	unsubscribe() {
		if (this.unsubscribeProfessional)
			this.unsubscribeProfessional()

		if (this.unsubscribeInstitutions)
			this.unsubscribeInstitutions()
	}

	onInstitutionsUpdate = (querySnapshot: any) => {
		const institutions = []
		querySnapshot.forEach((doc) => {
			const { name, shortName } = doc.data()
			institutions.push({
				id: doc.id,
				ref: doc.ref,
				name,
				shortName
			})
		})
		this.setState({
			institutions
		})

		this.updateInstitutionList()
		console.warn("PROFESSIONAL SUB 2")
	}

	onProfessionalUpdate = (doc: any) => {
		const { displayName, institutions, appointmentDuration, startTime, workDays, absences, status } = doc.data()

		const professional = {
			ref: doc.ref,
			id: doc.id,
			name: displayName,
			institutions,
			appointmentDuration,
			startTime,
			workDays,
			absences,
			status
		}

		this.setState({
			professional
		},
			() => {
				this.updateInstitutionList()
				this.props.onSelectedProfessional(professional)
			})

		console.warn("PROFESSIONAL SUB 3")
	}

	updateInstitutionList = () => {
		const { professional } = this.state
		const institutions = []

		const findInstitution = (a: any, institution: any) => {
			return a.id === institution
		}

		if (professional) {
			for (var institution in professional.institutions) {
				var item = this.state.institutions.find((a) => findInstitution(a, institution))

				if (item) {
					institutions.push({
						ref: item.ref,
						id: item.id,
						name: item.name
					})
				}
			}
		}
		this.setState({
			institutionList: institutions
		}, () => {
			if (institutions.length > 0 && !this.state.selectedInstitutionRef) {
				this.onChangeSelectedInstitution({ target: { value: institutions[0].id } })
			}
		})
	}

	getInstitution = (id: any) => {
		var found = this.state.institutions.find((a) => {
			return a.id === id
		})

		if (found) {
			return found
		}

		return undefined
	}

	onChangeSelectedInstitution = (event: any) => {
		const institutionId = event.target.value
		const institutionRef = firebase.firestore().collection("institutions").doc(institutionId)

		this.setState({
			selectedInstitutionRef: institutionRef
		})

		this.props.onSelectedInstitution(this.getInstitution(institutionId))
	}

	render() {
		const institutions = this.state.institutionList
		const { selectedInstitutionRef } = this.state

		return (
			<div className="center">
				{
					institutions.length > 1 &&
					<div className="institution-select">
						<select onChange={this.onChangeSelectedInstitution}>
							{
								institutions.map((institution) => {
									return (
										<option key={institution.id} value={institution.id} selected={selectedInstitutionRef && institution.id === selectedInstitutionRef.id ? "selected" : undefined}>{institution.name}</option>
									)
								})
							}
						</select>
					</div>
				}
			</ div >
		)
	}
}