// @flow

import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import * as ROUTES from "../../constants/routes"
import { login, logout } from "../../utils/auth"
import validate from "../../utils/validation"
import { Status } from "../../constants/values"

type Props = {
  history: any,
  user: any,
  onLoginComplete: Function
}

type LocalState = {
  email: ?string,
  password: ?string,
  user: ?any,
  error: any
}

class Login extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      user: props.user,
      error: {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.login = this.login.bind(this)
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.history.push(ROUTES.APPOINTMENTS)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.user) {
      if (nextProps.user.status === Status.Accepted) {
        this.props.history.push(ROUTES.APPOINTMENTS)
      }
      else {
        this.props.history.push(ROUTES.DASHBOARD)
      }
    }
  }

  handleChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  validateForm() {
    var error = {
      email: validate("email", this.state.email),
      password: validate("password", this.state.password)
    }

    this.setState({
      error
    })

    if (error.email || error.password) {
      return false
    }

    return true
  }

  login = (event: any) => {
    const { email, password } = this.state

    if (!this.validateForm()) {
      event.preventDefault()
      return
    }

    this.setState({
      error: {}
    })

    login(email, password)
      .then((user) => {
        this.props.onLoginComplete(user)
        if (this.props.user.status === Status.Accepted) {
          this.props.history.push(ROUTES.APPOINTMENTS)
        }
        else {
          this.props.history.push(ROUTES.DASHBOARD)
        }
      })
      .catch((e) => {
        console.error(e)
        logout()
        this.setState({
          error: {
            ...this.state.error,
            response: e
          }
        })
      })
    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <div className="login">
        <h1>Iniciar Sesión</h1>
        <section>
          <form onSubmit={this.login}>
            <input type="text" name="email" placeholder="email@domain.com" autoComplete="username" onChange={this.handleChange} value={this.state.email} />
            <input type="password" name="password" placeholder="contraseña" autoComplete="current-password" onChange={this.handleChange} value={this.state.password} />
            {
              error.email &&
              <div className="errorLabel">
                {error.email}
              </div>
            }
            {
              error.password &&
              <div className="errorLabel">
                {error.password}
              </div>
            }
            {
              error.response &&
              <div className="errorLabel">
                {error.response}
              </div>
            }
            <button className="create-button">Iniciar Sesión</button>
          </form>
        </section>
      </div >
    )
  }
}

export default withRouter(Login)