//  @flow

import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import { logout } from "../../utils/auth"
import * as ROUTES from "../../constants/routes"
import { Status } from "../../constants/values"
import logo from "../../assets/logo.png"

type Props = {
  match: any,
  location: any,
  history: any,
  user: any
}

type LocalState = {
  user: ?any
}

class Navigation extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      user: props.user
    }

    this.logout = this.logout.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        user: nextProps.user
      })
    }
  }

  logout = () => {
    confirmAlert({
      title: "Cerrar Sesión",
      message: "Esta seguro que desea cerrar la sesión?",
      buttons: [
        {
          label: "Si",
          onClick: () => logout().then(() => {
            this.props.history.push(ROUTES.LOGIN)
          })
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    })
  }

  render() {
    return (
      <ul className="navBar">
        <div className="logo-and-title">
          <img src={logo} alt="logo" className="logo" />
          <div className="title">Aledo Doctor</div>
        </div>
        {!this.state.user &&
          <li className="navOption">
            <NavLink activeClassName="activeLink" to={ROUTES.LOGIN}>Inicio</NavLink>
          </li>
        }
        {this.state.user &&
          <li className="navOption">
            <NavLink activeClassName="activeLink" to={ROUTES.DASHBOARD}>Inicio</NavLink>
          </li>
        }
        {this.state.user &&
          <li className="navOption">
            < NavLink activeClassName="activeLink" to={ROUTES.CLIENTS} >Clientes</NavLink>
          </li>
        }
        {this.state.user && this.state.user.status === Status.Accepted &&
          <li className="navOption">
            <NavLink activeClassName="activeLink" to={ROUTES.APPOINTMENTS}>Citas</NavLink>
          </li>
        }
        {
          this.state.user &&
          <li className="navOption">
            <button className="buttonLink" onClick={this.logout}>Cerrar Sesión</button>
          </li>
        }
      </ul>
    )
  }
}

export default withRouter(Navigation)