
import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { slide as Menu } from "react-burger-menu"

import { logout } from "../../utils/auth"
import * as ROUTES from "../../constants/routes"
import { Status } from "../../constants/values"

type Props = {
	match: any,
	location: any,
	history: any,
	user: any
}

type LocalState = {
	user: ?any,
	isOpen: Boolean
}

class Navigation extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props)

		this.state = {
			user: props.user,
			isOpen: false
		}

		this.logout = this.logout.bind(this)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.user !== nextProps.user) {
			this.setState({
				user: nextProps.user
			})
		}
	}

	handleStateChange(state) {
		this.setState({ isOpen: state.isOpen })
	}

	closeMenu = () => {
		this.setState({
			isOpen: false
		})
	}

	logout = () => {
		this.closeMenu()
		confirmAlert({
			title: "Cerrar Sesión",
			message: "Esta seguro que desea cerrar la sesión?",
			buttons: [
				{
					label: "Si",
					onClick: () => logout().then(() => {
						this.props.history.push(ROUTES.LOGIN)
					})
				},
				{
					label: "No",
					onClick: () => { }
				}
			]
		})
	}

	render() {
		return (
			<Menu isOpen={this.state.isOpen}
				onStateChange={(state) => this.handleStateChange(state)}>
				{!this.state.user &&
					<li className="navOption">
						<NavLink activeClassName="activeLink" to={ROUTES.LOGIN} onClick={this.closeMenu}>Inicio</NavLink>
					</li>
				}
				{this.state.user &&
					<li className="navOption">
						<NavLink activeClassName="activeLink" to={ROUTES.DASHBOARD} onClick={this.closeMenu}>Inicio</NavLink>
					</li>
				}
				{this.state.user &&
					<li className="navOption">
						< NavLink activeClassName="activeLink" to={ROUTES.CLIENTS} onClick={this.closeMenu} >Clientes</NavLink>
					</li>
				}
				{this.state.user && this.state.user.status === Status.Accepted &&
					<li className="navOption">
						<NavLink activeClassName="activeLink" to={ROUTES.APPOINTMENTS} onClick={this.closeMenu}>Citas</NavLink>
					</li>
				}
				{
					this.state.user &&
					<li className="navOption">
						<button className="buttonLink" onClick={this.logout}>Cerrar Sesión</button>
					</li>
				}
			</Menu >
		)
	}
}

export default withRouter(Navigation)