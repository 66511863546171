// @flow

const sortByName = (a: any, b: any) => {
	if (a.name < b.name) {
		return -1
	}
	if (a.name > b.name) {
		return 1
	}
	return 0
}

export default {
	sortByName
}