
// @flow

import React from "react"

type Props = {
	client: any,
	isProfessional: boolean,
	onPressItem: ?Function
}

type LocalState = {
	client: any
}

export default class ClientItem extends React.PureComponent<Props, LocalState> {
	static defaultProps = {
		onPressItem: undefined
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			client: props.client
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.client !== nextProps.client) {
			this.setState({
				client: nextProps.client
			})
		}
	}

	onPress = () => {
		if (this.props.onPressItem) {
			this.props.onPressItem(this.props.client)
		}
	}

	render() {
		const { client } = this.state
		const { id, name } = client

		return (
			<div key={id} onClick={this.onPress}>
				<div>
					{name &&
						<label >{name}</label>
					}
				</div>
			</div>
		)
	}
}